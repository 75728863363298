import axios from 'axios';
import React, { message, Spin } from 'antd';
import ReactDOM from 'react-dom';

const parseResponse = ( res, success, fail ) => {
  if ( res.status == 0 ) {
    success( res );
  } else if ( res.status == 9999 ) {
    message.error( res.msg );
    window.location.href = '/login';
  } else {
    message.error( `${ res.msg }` );
    fail && fail( res );
  }
};

let loadingCount = 0;
export const showLoading = () => {
  if ( loadingCount == 0 ) {
    setTimeout( () => {
      if ( loadingCount > 0 ) {
        const dom = document.createElement( 'div' );
        dom.setAttribute( 'id', 'id-loading' );
        dom.setAttribute( 'class', 'id-loading' );
        document.body.appendChild( dom );
        ReactDOM.createRoot( dom ).render( <Spin tip="处理中..." size="large" delay={ 300 } /> );
      }
    }, 300 );
  }
  loadingCount += 1;
};
export const hideLoading = () => {
  loadingCount -= 1;
  if ( loadingCount == 0 && document.getElementById( 'id-loading' ) != null ) {
    document.body.removeChild( document.getElementById( 'id-loading' ) );
  }
};
export function post( url, param = {}, config = {} ) {
  return new Promise( ( success, fail ) => {
    if ( config.loading ) {
      showLoading();
    }
    axios.post( url, param ).then( ( res ) => {
      if ( config.loading ) {
        hideLoading();
      }
      if ( !config.ignoreParse ) {
        parseResponse( res.data, success, fail );
      } else {
        success( res );
      }
    }, ( err ) => {
      if ( err.response && err.response.status == 401 ) {
        window.location.href = '/login';
      }
      console.log( err );
      if ( config.loading ) {
        hideLoading();
      }
      message.error( `${ err.message }  [${ err.config.url }]` );
      fail && fail( err );
    } );
  } );
}

export async function syncPost( url, param = {} ) {
  const res = await axios.post( url, param );
  return res.data;
}

export async function syncGet( url, param = {} ) {
  const res = await axios.get( url, param );
  return res.data;
}
export function get( url, param = {}, config = {} ) {
  return new Promise( ( success, fail, reject ) => {
    axios.get( url, param ).then( ( res ) => {
      if ( !config.ignoreParse ) {
        parseResponse( res.data, success, fail );
      } else {
        success( res );
      }
    }, ( err ) => {
      reject && reject( err );
    } );
  } );
}

export function getParam( code ) {
  return new URLSearchParams( window.location.search ).get( code );
}


export function getImgBase64( url ) {
  return new Promise( ( success, fail ) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.open( 'GET', url );
      xhr.send();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          const base64 = reader.result;
          success( base64 );
        };
        reader.readAsDataURL( xhr.response );
      };
    } catch ( e ) {
      fail( e );
    }
  } );
}

export function getImgUrl( url ) {
  if ( url.startsWith( 'http' ) ) {
    return url;
  } else {
    return `https://qupai-aigen-oss.oss-cn-chengdu.aliyuncs.com/${ url }`;
  }
}