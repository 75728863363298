import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Upload,
  Image,
} from 'antd';
import { getImgUrl, get, syncGet } from 'util/http';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


function Page( {
  value,
  onChange,
  afterUpload,
  readOnly = false,
  maxCount = 1,
  display = 'flex',
  label = '上传图片',
  uploadData = {},
  style = {
    display: 'flex',
    textAlign: 'center'
  },
  previewImg = false,
  folder = 'materials/',
  uploadUrl = 'https://qupai-aigen-oss.oss-cn-chengdu.aliyuncs.com',
  imageStyle = {
    maxHeight: 100
  },
  ...nextProps
} ) {
  const [ images, setImages ] = useState( [] );

  useEffect( () => {
    if ( value != null ) {
      if ( typeof value === 'string' ) {
        if ( value.indexOf( ',' ) > -1 ) {
          setImages( value.split( ',' ) );
        } else {
          setImages( [ value ] );
        }
      } else if ( Array.isArray( value ) ) {
        setImages( value );
      } else {
        setImages( [ value ] );
      }
    }
    // if (value != null && value != '' && value.indexOf(',') > -1) {
    //     setImages(value.split(','))

    // } else if (value != null && value != '') {
    //     setImages([value])
    // }
  }, [ value ] );

  return (
    <div style={ style }>

      <div style={ { marginTop: 10 } }></div>
      <Upload
        action={ uploadUrl }
        showUploadList={ false }
        maxCount={ maxCount }
        multiple={ maxCount > 1 }
        name="file"
        data={ async () => {
          const defaultParams = await syncGet( '/api/photo/oss/token/get', {} );
          return {
            ...uploadData,
            key: folder + moment( new Date() ).format( 'YYYY/MM/DD' ) + "/" + uuidv4() + ".png",
            OSSAccessKeyId: defaultParams.data.accessid,
            policy: defaultParams.data.policy,
            signature: defaultParams.data.signature,
            callback: defaultParams.data.callback,
            success_action_status: '200',
          };
        } }
        onChange={ ( info ) => {
          if ( info.file.status === 'done' ) {
            if ( maxCount == 1 ) {
              onChange( info.file.response.data.url );
            } else {
              const newValue = [];
              info.fileList.map( ( file ) => {
                newValue.push( file.response.data.url );
              } );
              onChange( newValue );
            }
            if ( afterUpload ) {
              afterUpload( info.file.response.data, info.file );
            }
          } else if ( info.file.status === 'error' ) {
            message.error( `${ info.file.name } 上传失败` );
          }
        } }
        { ...nextProps }
      >
        { !readOnly && <Button icon={ <UploadOutlined /> }>{ label }</Button> }
      </Upload>
      <div></div>
      { maxCount == 1 && value != null && value != '' && (
        <Image src={ getImgUrl( value ) } style={ imageStyle } />
      ) }
      { maxCount > 1 && value != null && (
        images.map( img => (
          <div style={ { marginRight: 20, position: "relative" } } key={ img }>
            <Image src={ getImgUrl( img ) } style={ imageStyle }
              preview={ previewImg }
            />
            <DeleteOutlined style={ {
              position: 'absolute',
              fontSize: 20,
              cursor: 'pointer',
              bottom: 0,
              right: 0
            } }
              onClick={ () => {
                const newImages = images.filter( image => image !== img );
                setImages( newImages );
                onChange( newImages );
              } }
            />
          </div>
        ) )
      ) }
    </div >
  );
};

export default Page;
