import React, { useState } from 'react'
import { Button, Input, Space, Popconfirm, Form, message } from 'antd';
import { post } from 'util/http';
import module from 'util/module';
import SearchTable from 'component/SearchTable.js';
import { addPage, addRoute } from 'component/DynamicRotes.js';
import FormDrawer from './form.js';



const Page = () => {

    const metaCode = "cmsTerminal";
    const [ param, setParam ] = useState( {
        metaCode: metaCode,
        showRoot: false,
        nameAttr: "title"
    } );
    const [ formParam, setFormParam ] = useState( { metaCode: metaCode } );
    const [ searchForm ] = Form.useForm();

    const reload = () => {
        setParam( {
            ...param,
            ...searchForm.getFieldsValue()
        } )
    }
    return (
        <div className="page-content">
            <Form form={ searchForm } layout="inline"  >

                <Form.Item
                    name="searchName"
                >
                    <Input
                        style={ { width: '100%' } }
                        placeholder="请输入名称"
                    />
                </Form.Item>
                <Space >
                    <Button onClick={ () => {
                        reload();
                    } }>搜索</Button>
                    <Button type="primary" onClick={ () => {
                        setFormParam( {
                            data: null,
                            visible: true
                        } )
                    } }>创建</Button>

                </Space>


            </Form>

            <SearchTable url={ "/api/photo/tag/search" }
                param={ param }
                columns={ [
                    {
                        title: '分类',
                        dataIndex: 'group_name',
                        key: 'group_name',
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '前景',
                        dataIndex: 'foreground',
                        key: 'foreground',
                        render: ( text ) => {
                            return text == 1 ? "是" : "否";
                        }
                    },
                    {
                        title: '背景',
                        dataIndex: 'background',
                        key: 'background',
                        render: ( text ) => {
                            return text == 1 ? "是" : "否";
                        }
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '顺序',
                        dataIndex: 'show_order',
                        key: 'show_order',
                    },
                    {
                        title: '操作',
                        dataIndex: 'name',
                        key: 'name',
                        render: ( _, record ) => (
                            <Space size="middle">
                                <Button type="link" onClick={ () => {
                                    setFormParam( {
                                        data: record,
                                        visible: true
                                    } )
                                } }>修改</Button>
                                <Popconfirm
                                    title="你确认删除此数据么?"
                                    onConfirm={ () => {
                                        post( '/api/photo/tag/delete', {
                                            code: record.code
                                        } ).then( res => {
                                            setParam( { ...param } );
                                            message.info( "删除成功" );
                                        } )
                                    } }
                                    okText="确认"
                                    cancelText="取消" >
                                    <Button type="link" href="#">删除</Button>

                                </Popconfirm>

                            </Space>
                        )
                    }
                ] } />
            <FormDrawer param={ formParam } setParam={ setFormParam } reload={ () => { setParam( { ...param } ) } } />
        </div >
    );
}
addPage( "/photo/tag/manager", Page );
addPage( "*", Page )
addRoute( "/tagManager", Page );

export default Page;