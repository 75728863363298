/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import { Button, Space, Form, Row, Col, Upload, Image, message, Select } from 'antd';
import { post, getParam } from 'util/http';
import { addPage } from 'component/DynamicRotes.js';
const { Dragger } = Upload;

function Page() {
    const [ srcBase64Data, setSrcBase64Data ] = useState( null );
    const [ faceBase64Data, setFaceBase64Data ] = useState( null );
    const [ targetImg, setTargetImg ] = useState( null );
    const [ pageForm ] = Form.useForm();


    const checkFaceImg = () => {
        if ( faceBase64Data == null || faceBase64Data == '' ) {
            message.error( '请先选择脸图片' );
            return false;
        }
        return true;
    };
    const checkSrcImg = () => {
        if ( srcBase64Data == null || srcBase64Data == '' ) {
            message.error( '请先选择目标图片' );
            return false;
        }
        return true;
    };



    const changeFace = () => {
        if ( !checkFaceImg() || !checkSrcImg() ) {
            return;
        }
        post( '/api/photo/face/change', {
            src_base64: srcBase64Data.substring( srcBase64Data.indexOf( ',' ) + 1 ),
            face_base64: faceBase64Data.substring( faceBase64Data.indexOf( ',' ) + 1 ),
            return_base64: true,
        }, {
            loading: true,
        } ).then( ( res ) => {
            setTargetImg( "data:image/png;base64," + res.data );
        } );
    };

    useEffect( () => {
        const srcUrl = getParam( 'src_url' );
        if ( srcUrl != null && srcUrl != '' ) {
            try {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.open( 'GET', srcUrl );
                xhr.send();
                xhr.onload = function () {
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        const base64 = reader.result;
                        setSrcBase64Data( base64 );
                    };
                    reader.readAsDataURL( xhr.response );
                };
            } catch ( e ) {
                console.log( e );
            }
        }
        pageForm.setFieldsValue( {
            makeup_id: '30001',
        } );
    }, [] );
    return (
        <div className="page-content">
            <Form
                form={ pageForm }
                labelCol={ {
                    style: { width: 120 },
                } }
            >
                <div style={ { marginTop: 10 } }>
                    <Button
                        type="primary"
                        onClick={ () => {
                            changeFace();
                        } }
                    >换脸
                    </Button>
                </div>

            </Form>

            <div style={ { marginTop: 20 } }>
                <Row>

                    <Col span={ 8 }>
                        <Dragger
                            style={ { maxHeight: 50, width: '80%' } }
                            action={ file => new Promise( () => {
                                const reader = new FileReader();
                                reader.readAsDataURL( file );
                                reader.onload = () => {
                                    setFaceBase64Data( reader.result );
                                };
                            } ) }
                        >拖拽脸图片到这里
                        </Dragger>
                        { faceBase64Data != null && <Image src={ faceBase64Data } width="80%" /> }
                    </Col>
                    <Col span={ 8 }>
                        <Dragger
                            style={ { maxHeight: 50, width: '80%' } }
                            action={ file => new Promise( () => {
                                const reader = new FileReader();
                                reader.readAsDataURL( file );
                                reader.onload = () => {
                                    setSrcBase64Data( reader.result );
                                };
                            } ) }
                        >拖拽目标图片到这里
                        </Dragger>
                        { srcBase64Data != null && <Image src={ srcBase64Data } width="80%" /> }
                    </Col>
                    <Col span={ 8 }>

                        { targetImg != null && <Image src={ targetImg } width="80%" /> }

                    </Col>

                </Row>
            </div>


        </div>
    );
};
addPage( '/photo/face/change', Page );

export default Page;
