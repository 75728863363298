/* eslint-disable react/jsx-indent */
import React from 'react';
import { Spin, ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';

import locale from 'antd/lib/locale/zh_CN';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
window.spinning = false;


root.render(<BrowserRouter>
  <ConfigProvider locale={locale}>
    <Spin tip="加载中..." spinning={window.spinning} size="large" delay={200}>
      <App />
    </Spin>
  </ConfigProvider>
            </BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
