import React, { useState, useEffect } from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import { addRoute } from 'component/DynamicRotes';
import EditImage, { EditImageContext } from 'component/EditImage';
import MaterialPicker from 'pages/photo/material/picker';
import BackgroundPicker from 'pages/photo/background/picker';
import ForegroundPicker from 'pages/photo/foreground/picker';
import { Button, Row, Col, Tabs, Space, Select, Form, message, Input, Image, Menu } from 'antd';
import { post, getParam, getImgUrl } from 'util/http';
import { v4 as uuidv4 } from 'uuid';
import SearchSelect from 'component/SearchSelect';
import GroupTagSelect from 'component/GroupTagSelect';
import Sider from 'antd/lib/layout/Sider';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';



const App = () => {


    const [ imgResult, setImgResult ] = useState( {
        limit: 12,
        page: 1,
        total: 0,
        records: [],
    } );
    const loadData = ( start = 0, limit = 24 ) => {
        post( '/api/photo/frame/search', {
            status: "online",
            start,
            limit,
        }, {
            loading: true,
        } ).then( ( res ) => {
            setImgResult( res.data );
        } );
    };

    const showLength = ( str, len ) => {
        if ( str == null ) {
            return '';
        } if ( str.length > len ) {
            return `${ str.substring( 0, len ) }...`;
        }
        return str;
    };


    const [ leftCollapsed, setLeftCollapsed ] = useState( false );
    const [ rightCollapsed, setRightCollapsed ] = useState( false );
    const [ styleSetting, setStyleSetting ] = useState( {
        width: 0,
        height: 0,
        materials: [],
        dig_config: []
    } )

    const [ rectangles, setRectangles ] = useState( [] )
    const [ selectId, setSelectId ] = useState( '' );
    const [ pageForm ] = Form.useForm();


    const [ scale, setScale ] = useState( 0.3 )

    const copyMaterial = ( id ) => {
        const material = styleSetting.materials.find( material => material.id === id );
        const newMaterial = JSON.parse( JSON.stringify( material ) );
        newMaterial.id = uuidv4();
        styleSetting.materials.push( newMaterial );
        setStyleSetting( { ...styleSetting } );
    }

    const createRectangle = ( styleSetting ) => {
        let res = [];
        if ( styleSetting ) {
            const { leftSpace, topSpace, elementWidth, elementHeight, colSpace, rowSpace, rowCount = 0, colCount = 0 } = styleSetting;
            for ( let i = 0; i < rowCount; i++ ) {
                for ( let j = 0; j < colCount; j++ ) {
                    res.push( [
                        leftSpace + j * ( elementWidth + colSpace ),
                        topSpace + i * ( elementHeight + rowSpace ),
                        leftSpace + j * ( elementWidth + colSpace ) + elementWidth,
                        topSpace + i * ( elementHeight + rowSpace ) + elementHeight
                    ] );
                }
            }
        }

        return res;
    }


    useEffect( () => {
        loadData();
        const code = getParam( 'code' );
        if ( code ) {
            post( '/api/photo/frame/get', {
                code: code,
            }, ).then( ( res ) => {
                const newFrame = res.data;
                newFrame.tags = newFrame.tags.split( ',' ) || [];
                const newStyleSetting = JSON.parse( newFrame.setting );
                setStyleSetting( newStyleSetting )
                if ( newStyleSetting.dig != 'false' ) {
                    const newRectangles = createRectangle.apply( null, [ newStyleSetting ] );
                    setRectangles( newRectangles );
                }
                newFrame.dig = newStyleSetting.dig || 'true';
                pageForm.setFieldsValue( newFrame )
            } );
        } else {
            const newStyleSetting = {
                width: 1920,
                height: 2860,
                materials: [],
                rowCount: 4,
                colCount: 2,
                elementWidth: 773,
                elementHeight: 579,
                leftSpace: 95,
                topSpace: 124,
                colSpace: 160,
                rowSpace: 27,
                bg_src: "backgrounds/2024/04/14/23ab950d-0647-4d44-ac5a-c51b298ad431.png",
                fg_src: ""
            }
            setStyleSetting( newStyleSetting )
            const newRectangles = createRectangle.apply( null, [ newStyleSetting ] );
            setRectangles( newRectangles );
            pageForm.setFieldsValue( {
                status: 'online',
                dig: 'true',
                name: "新相框",
                style_code: "default",
                tags: [ "通用" ]
            } )
        }

    }, [] )



    return (
        <div style={ {
            minHeight: 800,
            // display: 'flex',
            // justifyContent: 'center',
        } }>

            <Row wrap={ false }>
                <Col flex={ leftCollapsed ? "40px" : "400px" }
                    style={ {
                        padding: 10
                    } }>
                    { leftCollapsed && <DoubleRightOutlined onClick={ ( e ) => {
                        setLeftCollapsed( false )
                    } }
                        style={ {
                            float: 'right',
                            cursor: 'pointer'
                        } } /> }
                    { !leftCollapsed && < DoubleLeftOutlined
                        onClick={ ( e ) => {
                            setLeftCollapsed( true )
                        } }
                        style={ {
                            float: 'right',
                            cursor: 'pointer'
                        } } /> }
                    { !leftCollapsed && <Tabs items={ [
                        {
                            label: '背景', key: 'background', children:
                                <BackgroundPicker alfterSelect={ ( img ) => {
                                    styleSetting.bg_src = img.src_url;
                                    setStyleSetting( { ...styleSetting } );
                                } } />
                        },
                        {
                            label: '前景', key: 'foreground', children:
                                <ForegroundPicker alfterSelect={ ( img ) => {
                                    styleSetting.fg_src = img.src_url;
                                    setStyleSetting( { ...styleSetting } );
                                } } />
                        },
                        {
                            label: '素材', key: 'material', children:

                                <MaterialPicker alfterSelect={ ( img ) => {
                                    const newMaterial = {
                                        id: uuidv4(),
                                        x: 0,
                                        y: 0,
                                        width: img.width,
                                        height: img.height,
                                        src_url: img.src_url,
                                        material_group_tags: {}
                                    }
                                    styleSetting.materials.push( newMaterial );
                                    setStyleSetting( { ...styleSetting } );
                                } } />
                        }
                    ] } /> }
                </Col>
                <Col flex={ "auto" } style={ {
                    maxHeight: window.innerHeight,
                    overflow: 'auto',
                    position: 'relative'
                } }>
                    <Select
                        value={ scale }
                        style={ {
                            width: 100,
                            position: 'absolute',
                            top: 10,
                            zIndex: 100,
                            right: 10
                        } } onChange={ ( value ) => {
                            setScale( value )
                        } }>
                        <Select.Option value={ 0.1 }>10%</Select.Option>
                        <Select.Option value={ 0.3 }>30%</Select.Option>
                        <Select.Option value={ 0.5 }>50%</Select.Option>
                        <Select.Option value={ 0.8 }>80%</Select.Option>
                        <Select.Option value={ 1 }>100%</Select.Option>
                    </Select>
                    <EditImageContext.Provider value={ { selectId, setSelectId } }>
                        <Stage width={ styleSetting.width } height={ styleSetting.height } scale={ { x: scale, y: scale } } style={ {
                            width: styleSetting.width * scale,
                            height: styleSetting.height * scale,
                            overflow: 'hidden',
                            border: '1px solid #ccc',
                        } }
                            onMouseDown={ () => {
                                setSelectId( null );
                            } }>
                            <Layer>
                                { styleSetting.bg_src != null && styleSetting.bg_src != '' && <EditImage
                                    id={ "id-bg" }
                                    readonly={ true }
                                    value={ {
                                        "x": 0,
                                        "y": 0,
                                        "width": styleSetting.width || 1920,
                                        "height": styleSetting.height || 2860,
                                        "src_url": styleSetting.bg_src || ""
                                    } }
                                /> }
                                { rectangles.map( ( rectangle, index ) => (
                                    <Rect
                                        key={ index }
                                        x={ rectangle[ 0 ] }
                                        y={ rectangle[ 1 ] }
                                        width={ rectangle[ 2 ] - rectangle[ 0 ] }
                                        height={ rectangle[ 3 ] - rectangle[ 1 ] }
                                        fill="white"
                                    // stroke="black" // 边框颜色  
                                    // strokeWidth={ 2 } // 边框宽度  
                                    />
                                ) ) }
                                { styleSetting.fg_src != null && styleSetting.fg_src != '' && <EditImage
                                    id={ "id-bg" }
                                    readonly={ true }
                                    value={ {
                                        "x": 0,
                                        "y": 0,
                                        "width": styleSetting.width || 1920,
                                        "height": styleSetting.height || 2860,
                                        "src_url": styleSetting.fg_src || ""
                                    } }
                                /> }

                                {
                                    styleSetting.materials &&
                                    styleSetting.materials.map( ( material, index ) => (
                                        <EditImage
                                            id={ material.id }
                                            key={ material.id }
                                            value={ material }
                                            onChange={ ( value ) => {
                                                styleSetting.materials[ index ] = value;
                                                setStyleSetting( { ...styleSetting } );
                                            } }
                                        />
                                    ) )
                                }
                            </Layer>
                        </Stage>

                    </EditImageContext.Provider>
                </Col>
                <Col
                    flex={ rightCollapsed ? "40px" : "400px" }
                    style={ {
                        padding: 10,
                    } }>
                    { rightCollapsed && <DoubleLeftOutlined onClick={ ( e ) => {
                        setRightCollapsed( false )
                    } }
                        style={ {
                            float: 'right',
                            cursor: 'pointer'
                        } } /> }
                    { !rightCollapsed && < DoubleRightOutlined
                        onClick={ ( e ) => {
                            setRightCollapsed( true )
                        } }
                        style={ {
                            float: 'right',
                            cursor: 'pointer'
                        } } /> }
                    { !rightCollapsed && <Tabs
                        items={ [
                            {
                                label: '设置', style: {
                                    height: window.innerHeight - 80,
                                    paddingBottom: 120
                                }, key: 'material', children:
                                    <div>
                                        <Form
                                            form={ pageForm }
                                            labelCol={ {
                                                style: { width: 80 },
                                            } }
                                        >
                                            <Form.Item name="code" style={ {
                                                display: 'none'
                                            } }>
                                                <Input />
                                            </Form.Item>
                                            <Form.Item name="name"
                                                rules={ [ { required: true, message: '请填写名称' } ] }
                                                label="名称" >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="style_code"
                                                label="规格"
                                                rules={ [ { required: true, message: '请选择规格' } ] }
                                            >
                                                <SearchSelect url='/api/photo/style/search' param={ {
                                                    status: 'online'
                                                } } onChange={ ( e, item ) => {
                                                    const newStyleSetting = {
                                                        ...styleSetting,
                                                        ...JSON.parse( item.data.setting )
                                                    }
                                                    setStyleSetting( newStyleSetting )
                                                    const newRectangles = createRectangle.apply( null, [ newStyleSetting ] );
                                                    setRectangles( newRectangles );
                                                } } />
                                            </Form.Item>
                                            <Form.Item
                                                name="tags"
                                                label="标签"
                                                rules={ [ { required: true, message: '请选择标签' } ] }
                                            >
                                                <GroupTagSelect
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="dig"
                                                label="扣除图层"
                                                rules={ [ { required: true, message: '请选择扣除图层' } ] }
                                            >
                                                <Select placeholder="扣除图层" onChange={ ( e ) => {
                                                    const newStyleSetting = { ...styleSetting, dig: e }
                                                    setStyleSetting( newStyleSetting )
                                                    if ( e != "false" ) {
                                                        const newRectangles = createRectangle.apply( null, [ newStyleSetting ] );
                                                        setRectangles( newRectangles );
                                                    } else {
                                                        setRectangles( [] );
                                                    }
                                                } }>
                                                    <Select.Option value={ "false" }>否</Select.Option>
                                                    <Select.Option value={ "true" }>是</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="status"
                                                label="状态"
                                                rules={ [ { required: true, message: '请选择状态' } ] }
                                            >
                                                <Select style={ { width: 100 } } placeholder="状态">
                                                    <Select.Option value="online">使用中</Select.Option>
                                                    <Select.Option value="underpick">待筛选</Select.Option>
                                                    <Select.Option value="offline">已下线</Select.Option>
                                                </Select>
                                            </Form.Item>

                                        </Form>
                                        <div style={ {
                                            // position: 'absolute',
                                            // bottom: 10, padding: 20,
                                            width: '100%'
                                        } }>

                                            <Button type='primary' block onClick={ () => {
                                                pageForm.validateFields().then( ( values ) => {
                                                    post( '/api/photo/frame/publish', {
                                                        ...values,
                                                        setting: JSON.stringify( styleSetting, null, 2 ),
                                                    }, {
                                                        loading: true,
                                                    } ).then( ( res ) => {
                                                        message.info( "发布成功" )
                                                        if ( values.code == null ) {
                                                            window.location.href = '/photo/frame/design?code=' + res.data;
                                                        }
                                                    } );
                                                } );

                                            } }>保存&发布</Button>
                                            <Button type='default' style={ {
                                                marginTop: 10
                                            } } block onClick={ () => {
                                                pageForm.validateFields().then( ( values ) => {
                                                    post( '/api/photo/frame/save', {
                                                        ...values,
                                                        setting: JSON.stringify( styleSetting, null, 2 ),
                                                    }, {
                                                        loading: true,
                                                    } ).then( ( res ) => {
                                                        message.info( "保存成功" )
                                                        if ( values.code == null ) {
                                                            window.location.href = '/photo/frame/design?code=' + res.data;
                                                        }
                                                    } );
                                                } );

                                            } }>保存</Button>
                                        </div>
                                    </div>

                            },
                            {
                                label: '元素', key: 'background', children:
                                    <div>
                                        <Space>

                                            { selectId && selectId != '' && <>
                                                <Button size='middle' onClick={ () => {
                                                    copyMaterial( selectId );
                                                } }>复制</Button>
                                                <Button size='middle' type="danger" onClick={ () => {
                                                    const index = styleSetting.materials.findIndex( material => material.id === selectId );
                                                    styleSetting.materials.splice( index, 1 );
                                                    setStyleSetting( { ...styleSetting } );
                                                } }>
                                                    删除
                                                </Button>
                                            </> }

                                        </Space>
                                    </div>
                            },
                            {
                                label: '我的相框', key: 'myframe', children:
                                    <div>
                                        <Row gutter={ 10 }>
                                            {
                                                imgResult.records.map( img => (
                                                    <Col
                                                        span={ 8 }
                                                        key={ `img-${ img.id }` }
                                                        style={ {
                                                            padding: 10,
                                                            textAlign: 'center',
                                                        } }
                                                    >
                                                        <div >
                                                            <Image
                                                                preview={ {
                                                                    src: getImgUrl( img.src_url ),
                                                                } }
                                                                src={ getImgUrl( img.src_url + "?x-oss-process=image/resize,w_200" ) }
                                                                style={ { maxWidth: 200, maxHeight: 200 } }
                                                            />
                                                        </div>
                                                        <div style={ { textAlign: 'center', marginTop: 10 } }>
                                                            <div>{ showLength( img.name, 8 ) }</div>
                                                            <Space>

                                                                <Button
                                                                    size="small"
                                                                    onClick={ () => {
                                                                        window.open( "/photo/frame/design?code=" + img.code )
                                                                    } }
                                                                >设计
                                                                </Button>
                                                            </Space>
                                                        </div>
                                                    </Col>
                                                ) )
                                            }

                                        </Row>
                                    </div>
                            }
                        ] } /> }




                </Col>
            </Row>

        </div >
    );
};
addRoute( '/photo/frame/design', App );

export default App;