import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Select,
    Form,
    Input,
    Space,
} from 'antd';
import CodeMirrorBox from 'component/CodeMirrorBox';
import ImageOssUpload from 'component/ImageOssUpload';

import { post } from 'util/http';


const Page = ( {
    param = {},
    setParam,
    reload
} ) => {

    const [ pageForm ] = Form.useForm();
    const [ formData, setFormData ] = useState( {} )



    useEffect( () => {


    }, [] )
    useEffect( () => {
        if ( param.visible ) {
            pageForm.resetFields();
            pageForm.setFieldsValue( param.data );
            setFormData( param.data )
        }
    }, [ param ] )

    return ( <Drawer title="规格管理"
        placement="right"
        width={ 560 }
        maskClosable={ false }
        onClose={ () => {
            setParam( { ...param, visible: false } )
        } }
        visible={ param.visible }
        extra={
            <Space>
                <Button
                    disabled={ param.code == null || param.code == '' }
                    type='danger' onClick={ () => {
                        post( '/api/photo/style/delete', {
                            code: param.code,
                        }, {
                            loading: true,
                        } ).then( () => {
                            setParam( { ...param, visible: false } );
                            reload();
                        } );
                    } }>删除</Button>
                <Button onClick={ () => {
                    setParam( { ...param, visible: false } )
                } }>取消</Button>
                <Button type="primary" onClick={ () => {
                    pageForm.validateFields().then( ( data ) => {
                        post( '/api/photo/style/save', {
                            ...data,
                        }, {
                            loading: true,
                        } ).then( () => {
                            setParam( { ...param, visible: false } );
                            reload();
                        } );
                    } )
                } }>
                    确认
                </Button>
            </Space>
        }
    >
        <Form
            form={ pageForm }
            labelCol={ {
                style: { width: 80 },
            } }
        >
            <Form.Item
                name="code"
                style={ { display: 'none' } }
            >
                <Input type='hidden' />
            </Form.Item>
            <Form.Item
                name="name"
                label="名称"
                rules={ [ { required: true, message: '请输入名称' } ] }
            >
                <Input placeholder="关键字模糊匹配" />
            </Form.Item>
            <Form.Item
                name="status"
                label="状态"
                rules={ [ { required: true, message: '请选择状态' } ] }
            >
                <Select style={ { width: 100 } } placeholder="状态" >
                    <Select.Option value="online">使用中</Select.Option>
                    <Select.Option value="offline">已下线</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                name="preview_img"
                label="预览图"
                rules={ [ { required: true, message: '请上传预览图' } ] }
            >
                <ImageOssUpload
                    label='上传本地图片'
                    folder='styles/'
                    imageStyle={ {
                        maxHeight: 200,
                        maxWidth: 200,
                        marginBottom: 10
                    } }
                    display={ 'block' }
                    imageHeight={ 200 }
                />
            </Form.Item>

            <Form.Item
                name="setting"
                label="配置"
                style={ { display: 'none' } }
            >
                <CodeMirrorBox
                    defaultValue={ formData.setting }
                > </CodeMirrorBox>
            </Form.Item>




        </Form>


    </Drawer > )
}

export default Page;