import React, { useState, useEffect } from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import { addRoute } from 'component/DynamicRotes';
import EditImage, { EditImageContext } from 'component/EditImage';
import { Button, Row, Col, Tabs, Input, Select, Form, message, InputNumber, Space } from 'antd';
import { getParam } from 'util/http';
import { post } from 'util/http';
import ImageOssUpload from 'component/ImageOssUpload';

const App = () => {

    const [ style, setStyle ] = useState( {
        setting: {},
    } );

    const [ rectangles, setRectangles ] = useState( [] )
    const [ selectId, setSelectId ] = useState( '' );
    const [ pageForm ] = Form.useForm();


    const [ scale, setScale ] = useState( 0.3 )


    const afterUpdateForm = () => {
        const formValue = pageForm.getFieldsValue();
        setStyle( formValue );
        const newRectangles = createRectangle.apply( null, [ formValue.setting ] );
        setRectangles( newRectangles );
    }


    const createRectangle = ( styleSetting ) => {
        let res = [];
        if ( styleSetting ) {
            const { leftSpace, topSpace, elementWidth, elementHeight, colSpace, rowSpace, rowCount = 0, colCount = 0 } = styleSetting;
            for ( let i = 0; i < rowCount; i++ ) {
                for ( let j = 0; j < colCount; j++ ) {
                    res.push( [
                        leftSpace + j * ( elementWidth + colSpace ),
                        topSpace + i * ( elementHeight + rowSpace ),
                        leftSpace + j * ( elementWidth + colSpace ) + elementWidth,
                        topSpace + i * ( elementHeight + rowSpace ) + elementHeight
                    ] );
                }
            }
        }

        return res;
    }


    useEffect( () => {
        const code = getParam( 'code' );
        if ( code ) {
            post( '/api/photo/style/get', {
                code: code,
            }, ).then( ( res ) => {
                const newStyle = res.data;
                newStyle.setting = JSON.parse( newStyle.setting );
                console.log( newStyle.setting )
                newStyle.setting.width = newStyle.setting.width || 1920;
                newStyle.setting.height = newStyle.setting.height || 2860;
                newStyle.setting.rowCount = newStyle.setting.rowCount || 4;
                newStyle.setting.colCount = newStyle.setting.colCount || 2;
                newStyle.setting.elementWidth = newStyle.setting.elementWidth || 773;
                newStyle.setting.elementHeight = newStyle.setting.elementHeight || 579;
                newStyle.setting.leftSpace = newStyle.setting.leftSpace || 95;
                newStyle.setting.topSpace = newStyle.setting.topSpace || 124;
                newStyle.setting.colSpace = newStyle.setting.colSpace || 160;
                newStyle.setting.rowSpace = newStyle.setting.rowSpace || 27;
                setStyle( newStyle );
                const newRectangles = createRectangle.apply( null, [ newStyle.setting ] );
                setRectangles( newRectangles );
                pageForm.setFieldsValue( newStyle )
            } );
        }

    }, [] )



    return (
        <div style={ {
        } }>
            <Row>

                <Col flex={ 1 } style={ {
                    maxWidth: window.innerWidth - 400,
                    maxHeight: window.innerHeight,
                    overflow: 'auto'
                } }>
                    <Select
                        value={ scale }
                        style={ {
                            width: 100,
                            position: 'absolute',
                            top: 10,
                            zIndex: 100,
                            right: 10
                        } } onChange={ ( value ) => {
                            setScale( value )
                        } }>
                        <Select.Option value={ 0.1 }>10%</Select.Option>
                        <Select.Option value={ 0.3 }>30%</Select.Option>
                        <Select.Option value={ 0.5 }>50%</Select.Option>
                        <Select.Option value={ 0.8 }>80%</Select.Option>
                        <Select.Option value={ 1 }>100%</Select.Option>
                    </Select>
                    <EditImageContext.Provider value={ { selectId, setSelectId } }>
                        <Stage width={ style.setting.width } height={ style.setting.height } scale={ { x: scale, y: scale } } style={ {
                            width: style.setting.width * scale,
                            height: style.setting.height * scale,
                            overflow: 'hidden',
                        } }
                            onMouseDown={ () => {
                                setSelectId( null );
                            } }>
                            <Layer>

                                <EditImage
                                    id={ "id-bg" }
                                    readonly={ true }
                                    value={ {
                                        "x": 0,
                                        "y": 0,
                                        "width": style.setting.width || 1920,
                                        "height": style.setting.height || 2860,
                                        "src_url": "backgrounds/2024/04/14/23ab950d-0647-4d44-ac5a-c51b298ad431.png"
                                    } }
                                />
                                { rectangles.map( ( rectangle, index ) => (
                                    <Rect
                                        key={ index }
                                        x={ rectangle[ 0 ] }
                                        y={ rectangle[ 1 ] }
                                        width={ rectangle[ 2 ] - rectangle[ 0 ] }
                                        height={ rectangle[ 3 ] - rectangle[ 1 ] }
                                        fill="white"
                                    />
                                ) ) }
                            </Layer>
                        </Stage>

                    </EditImageContext.Provider>
                </Col>
                <Col style={ {
                    width: 400,
                    padding: 10,
                    border: "1px solid #eee"
                } }>
                    <Form
                        form={ pageForm }
                        labelCol={ {
                            style: { width: 80 },
                        } }
                    >
                        <Tabs
                            items={ [
                                {
                                    label: '设置', style: { height: window.innerHeight - 80 }, key: 'material', children:
                                        <div  >
                                            <Form.Item
                                                name="code"
                                                style={ { display: 'none' } }
                                            >
                                                <Input type='hidden' />
                                            </Form.Item>
                                            <Form.Item
                                                name="name"
                                                label="名称"
                                                rules={ [ { required: true, message: '请输入名称' } ] }
                                            >
                                                <Input placeholder="关键字模糊匹配" />
                                            </Form.Item>
                                            <Form.Item label="相框宽高" >
                                                <Space.Compact >
                                                    <Form.Item
                                                        noStyle
                                                        name={ [ 'setting', 'width' ] }
                                                    >
                                                        <InputNumber onChange={ afterUpdateForm } min={ 100 } />
                                                    </Form.Item>
                                                    <span style={ {
                                                        lineHeight: '32px',
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        textAlign: 'center'
                                                    } }> x </span>
                                                    <Form.Item
                                                        noStyle
                                                        name={ [ 'setting', 'height' ] }
                                                    >
                                                        <InputNumber onChange={ afterUpdateForm } min={ 100 } />
                                                    </Form.Item>
                                                </Space.Compact>
                                            </Form.Item>

                                            <Form.Item label="行数列数" >
                                                <Space.Compact >
                                                    <Form.Item
                                                        noStyle
                                                        name={ [ 'setting', 'rowCount' ] }
                                                    >
                                                        <InputNumber onChange={ afterUpdateForm } min={ 1 } />
                                                    </Form.Item>
                                                    <span style={ {
                                                        lineHeight: '32px',
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        textAlign: 'center'
                                                    } }> x </span>
                                                    <Form.Item
                                                        noStyle
                                                        name={ [ 'setting', 'colCount' ] }
                                                    >
                                                        <InputNumber onChange={ afterUpdateForm } min={ 1 } />
                                                    </Form.Item>
                                                </Space.Compact>
                                            </Form.Item>

                                            <Form.Item label="元素宽高" >
                                                <Space.Compact >
                                                    <Form.Item
                                                        noStyle
                                                        name={ [ 'setting', 'elementWidth' ] }
                                                    >
                                                        <InputNumber onChange={ afterUpdateForm } />
                                                    </Form.Item>
                                                    <span style={ {
                                                        lineHeight: '32px',
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        textAlign: 'center'
                                                    } }> x </span>
                                                    <Form.Item
                                                        noStyle
                                                        name={ [ 'setting', 'elementHeight' ] }
                                                    >
                                                        <InputNumber onChange={ afterUpdateForm } />
                                                    </Form.Item>
                                                </Space.Compact>
                                            </Form.Item>

                                            <Form.Item label="左上间距" >
                                                <Space.Compact >
                                                    <Form.Item
                                                        name={ [ 'setting', 'leftSpace' ] }
                                                        noStyle
                                                    >
                                                        <InputNumber onChange={ afterUpdateForm } />
                                                    </Form.Item>
                                                    <span style={ {
                                                        lineHeight: '32px',
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        textAlign: 'center'
                                                    } }> x </span>
                                                    <Form.Item
                                                        name={ [ 'setting', 'topSpace' ] }
                                                        noStyle
                                                    >
                                                        <InputNumber onChange={ afterUpdateForm } />
                                                    </Form.Item>
                                                </Space.Compact>
                                            </Form.Item>

                                            <Form.Item label="行列间距" >
                                                <Space.Compact >
                                                    <Form.Item
                                                        name={ [ 'setting', 'rowSpace' ] }
                                                        noStyle
                                                    >
                                                        <InputNumber onChange={ afterUpdateForm } />
                                                    </Form.Item>
                                                    <span style={ {
                                                        lineHeight: '32px',
                                                        display: 'inline-block',
                                                        width: '20px',
                                                        textAlign: 'center'
                                                    } }> x </span>
                                                    <Form.Item
                                                        name={ [ 'setting', 'colSpace' ] }
                                                        noStyle
                                                    >
                                                        <InputNumber onChange={ afterUpdateForm } />
                                                    </Form.Item>
                                                </Space.Compact>
                                            </Form.Item>
                                            <Form.Item
                                                name="status"
                                                label="状态"
                                                rules={ [ { required: true, message: '请选择状态' } ] }
                                            >
                                                <Select style={ { width: 100 } } placeholder="状态" >
                                                    <Select.Option value="online">使用中</Select.Option>
                                                    <Select.Option value="offline">已下线</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="preview_img"
                                                label="预览图"
                                                rules={ [ { required: true, message: '请上传预览图' } ] }
                                            >
                                                <ImageOssUpload
                                                    label='上传本地图片'
                                                    folder='styles/'
                                                    imageStyle={ {
                                                        maxHeight: 200,
                                                        maxWidth: 200,
                                                        marginBottom: 10
                                                    } }
                                                    display={ 'block' }
                                                    imageHeight={ 200 }
                                                />
                                            </Form.Item>



                                        </div>
                                }
                            ] } />

                        <div style={ { position: 'absolute', bottom: 10, padding: 20, width: '100%' } }>
                            <Button type='primary' block onClick={ () => {
                                const data = pageForm.getFieldsValue();
                                data.setting = JSON.stringify( data.setting );
                                post( '/api/photo/style/save', {
                                    ...data,
                                }, {
                                    loading: true,
                                } ).then( ( res ) => {
                                    message.info( "保存成功" )
                                } );
                            } }>保存</Button>
                        </div>

                    </Form>


                </Col>
            </Row>

        </div >
    );
};
addRoute( '/photo/style/design', App );

export default App;