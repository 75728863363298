import React, { useCallback, useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import { post } from 'util/http';


function SearchTable( {
  columns,
  url,
  param = {
    start: 0,
    limit: 10,
  },
  keyCode = 'id',
  simplePagination = false,
  ...nextProps
} ) {
  const [ spinning, setSpinning ] = useState( false );
  const [ pageData, setPageData ] = useState( {
    start: 0,
    limit: 10,
  } );

  useEffect( () => {
    loadData( url, param );
  }, [ param ] );

  const loadData = useCallback( ( url, newParam ) => {
    setSpinning( true );
    post( url, {
      ...param,
      ...newParam,
    }, {
    } )
      .then( ( res ) => {
        setSpinning( false );
        setPageData( { ...res.data } );
      }, ( err ) => {
        console.log( err );
        setSpinning( false );
      } );
  }, [ param ] );

  return (
    <Spin tip="加载中..." spinning={ spinning } size="large" delay={ 200 }>
      <Table
        dataSource={ pageData.records }
        columns={ columns }
        pagination={ {
          simple: simplePagination,
          pageSize: pageData.limit,
          current: pageData.page,
          total: pageData.total,
          showTotal: total => `总共 ${ total } 条数据`,
          onChange: ( current, size ) => {
            if ( nextProps.onChange == null ) {
              loadData( url, {
                ...param,
                start: ( current - 1 ) * size,
                limit: size,
              } );
            }
          },
        } }
        rowKey={ record => record[ keyCode ] }
        { ...nextProps }
      />
    </Spin>
  );
};

export default SearchTable;
