
import React, { Button, Input, Form, Col, Row, message } from 'antd';
import { addRoute } from 'component/DynamicRotes';
import { post } from 'util/http';
import './login.css';


function Login() {
  const [ loginForm ] = Form.useForm();

  return (
    <div id="loginPage">
      <div className="login-poster" />
      <div className="login-area">
        <Form
          form={ loginForm }
          layout="horizontal"
          hideRequiredMark
          style={ {
            marginTop: 200,
            paddingLeft: 70,
          } }
          labelCol={ {
            style: {
              width: 80,
            },
          } }
          initialValues={ {
            items: [],
          } }
        >
          <Row gutter={ 16 }>
            <Col span={ 24 } style={ { marginBottom: 15 } }>
              <Form.Item
                name="username"
                rules={ [ { required: true, message: '请输入账号' } ] }
              >
                <Input
                  style={ { width: '100%' } }
                  size="large"
                  placeholder="请输入账号"
                />
              </Form.Item>

            </Col>
            <Col span={ 24 } style={ { marginBottom: 15 } }>
              <Form.Item
                name="password"
                rules={ [ { required: true, message: '请选输入密码' } ] }
              >
                <Input
                  type="password"
                  size="large"
                  style={ { width: '100%' } }
                  placeholder="请选输入密码"
                />
              </Form.Item>
            </Col>
            <Col span={ 24 } style={ { textAlign: 'center' } }>
              <Button
                block
                type="primary"
                size="large"
                onClick={ () => {
                  loginForm.validateFields().then( ( values ) => {
                    post( '/api/login', values )
                      .then( () => {
                        message.info( '登录成功' );
                        window.LOGIN_INFO = {
                          name: 'admin',
                        }
                        window.location.href = '/';
                      } );
                  } );
                } }
              >提交
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
addRoute( '/login', Login );
export default Login;
