import React, { useState, useEffect } from 'react';
import { Button, Space, message, Form, Row, Pagination, Col, Image, Modal, Select, Input, Checkbox, Tag, Tabs, Radio } from 'antd';
import { post, getParam, getImgUrl } from 'util/http';
import { addPage, addRoute } from 'component/DynamicRotes.js';
import FormDrawer from './form.js';
import GroupTagSelect from 'component/GroupTagSelect';

function Page() {
    const [ imgResult, setImgResult ] = useState( {
        limit: 12,
        page: 1,
        total: 0,
        records: [],
    } );
    const [ searchForm ] = Form.useForm();
    const [ tagForm ] = Form.useForm();
    const [ formParam, setFormParam ] = useState( {} );
    const [ isShowTagModal, setIsShowTagModal ] = useState( false );
    const [ checkMap, setCheckMap ] = useState( {} );


    const showLength = ( str, len ) => {
        if ( str == null ) {
            return '';
        } if ( str.length > len ) {
            return `${ str.substring( 0, len ) }...`;
        }
        return str;
    };

    const loadData = ( start = 0, limit = 24 ) => {
        const params = searchForm.getFieldsValue();
        post( '/api/photo/material/search', {
            ...params,
            start,
            limit,
        }, {
            loading: true,
        } ).then( ( res ) => {
            setImgResult( res.data );
        } );
    };

    useEffect( () => {
        searchForm.setFieldsValue( {
            tags: getParam( 'tags' ) ? getParam( 'tags' ).split( ',' ) : [],
            status: getParam( 'status' ) ? getParam( 'status' ) : 'online',
        } );
        loadData();
    }, [] );
    return (
        <div className="page-content">
            <div>

                <Form
                    form={ searchForm }
                    labelCol={ {
                    } }
                >
                    <Space style={ { float: 'right' } }>
                        <Form.Item
                            name="status"
                            style={ { marginBottom: 0 } }
                        >
                            <Select style={ { width: 100 } } placeholder="状态" onChange={ () => {
                                loadData();
                            } }>
                                <Select.Option value="online">使用中</Select.Option>
                                <Select.Option value="underpick">待筛选</Select.Option>
                                <Select.Option value="offline">已下线</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="searchName"
                            style={ { marginBottom: 0 } }
                        >
                            <Input placeholder="关键字模糊匹配" />
                        </Form.Item>
                        <Button
                            type="primary"
                            onClick={ () => {
                                loadData();
                            } }
                        >查询
                        </Button>

                        <Button style={ { marginLeft: 10 } } onClick={ () => {
                            // setIsShowUploadModal( true );
                            // uploadForm.resetFields();
                            setFormParam( {
                                visible: true,
                                title: '上传图片',
                                data: {}
                            } );
                        } }>创建</Button>

                    </Space>

                    <Form.Item
                        name="tags"
                        style={ { marginBottom: 0 } }
                    >
                        <GroupTagSelect
                            param={
                                {
                                    foreground: 1,
                                    limit: 10000
                                }
                            }
                            onChange={ ( v ) => {
                                loadData();
                            } }
                        />
                    </Form.Item>
                    {/* 
    */}





                </Form>

                <div style={ { clear: 'both', marginTop: 10 } }>

                    <Row gutter={ 10 }>
                        {
                            imgResult.records.map( img => (
                                <Col
                                    span={ 3 }
                                    key={ `img-${ img.id }` }
                                    style={ {
                                        padding: 10,
                                        textAlign: 'center',
                                    } }
                                >
                                    <div >
                                        <Image
                                            preview={ {
                                                src: getImgUrl( img.src_url ),
                                            } }
                                            src={ getImgUrl( img.src_url + "?x-oss-process=image/resize,w_100" ) }
                                            style={ { maxWidth: 100, maxHeight: 100 } }
                                        />
                                    </div>
                                    <div style={ { textAlign: 'center', marginTop: 10 } }>

                                        <Space>
                                            <Checkbox
                                                checked={ checkMap[ img.code ] }
                                                onChange={ ( v ) => {
                                                    setCheckMap( {
                                                        ...checkMap,
                                                        [ img.code ]: v.target.checked,
                                                    } );
                                                } }
                                            />
                                            <Button
                                                size="small"
                                                type="link"
                                                onClick={ () => {
                                                    setFormParam( {
                                                        visible: true,
                                                        code: img.code
                                                    } )
                                                } }
                                            >{ showLength( img.name, 8 ) }
                                            </Button>
                                        </Space>
                                    </div>
                                </Col>
                            ) )
                        }

                    </Row>

                    <div style={ { float: 'left', marginTop: 10, marginLeft: 10 } }>
                        <Space>
                            <Checkbox
                                checked={ Object.keys( checkMap ).length > 0 && Object.keys( checkMap ).every( k => checkMap[ k ] ) }
                                onChange={ ( v ) => {
                                    const newCheckMap = {};
                                    imgResult.records.forEach( ( img ) => {
                                        newCheckMap[ img.code ] = v.target.checked;
                                    } );
                                    setCheckMap( newCheckMap );
                                } }
                            />
                            <Button
                                type="danger"
                                size="small"
                                onClick={ () => {
                                    const codes = [];
                                    Object.keys( checkMap ).forEach( ( k ) => {
                                        if ( checkMap[ k ] ) {
                                            codes.push( k );
                                        }
                                    } );
                                    if ( codes.length == 0 ) {
                                        message.error( '请选择图片' );
                                        return;
                                    }
                                    Modal.confirm( {
                                        title: '确认删除',
                                        content: `确认删除${ codes.length }张图片`,
                                        onOk() {
                                            post( '/api/photo/material/batch_delete', {
                                                codes,
                                            }, {
                                                loading: true,
                                            } ).then( () => {
                                                setCheckMap( {} );
                                                loadData();
                                            } );
                                        },
                                    } );
                                } }
                            >批量删除
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                onClick={ () => {
                                    const codes = [];
                                    Object.keys( checkMap ).forEach( ( k ) => {
                                        if ( checkMap[ k ] ) {
                                            codes.push( k );
                                        }
                                    } );
                                    if ( codes.length == 0 ) {
                                        message.error( '请选择图片' );
                                        return;
                                    }
                                    setIsShowTagModal( true );
                                    tagForm.resetFields();
                                } }
                            >批量打签
                            </Button>
                            <Button
                                type="danger"
                                size="small"
                                onClick={ () => {
                                    const codes = [];
                                    Object.keys( checkMap ).forEach( ( k ) => {
                                        if ( checkMap[ k ] ) {
                                            codes.push( k );
                                        }
                                    } );
                                    if ( codes.length == 0 ) {
                                        message.error( '请选择图片' );
                                        return;
                                    }
                                    Modal.confirm( {
                                        title: '确认清空标签',
                                        content: `确认清空${ codes.length }张图片`,
                                        onOk() {
                                            post( '/api/photo/material/batch_clear_tag', {
                                                codes,
                                            }, {
                                                loading: true,
                                            } ).then( () => {
                                                loadData();
                                                message.success( '操作成功' );
                                            } );
                                        },
                                    } );
                                } }
                            >清空标签
                            </Button>

                            <Select style={ { width: 100 } } placeholder="变更状态" onChange={ ( v ) => {
                                const codes = [];
                                Object.keys( checkMap ).forEach( ( k ) => {
                                    if ( checkMap[ k ] ) {
                                        codes.push( k );
                                    }
                                } );
                                if ( codes.length == 0 ) {
                                    return
                                }
                                post( '/api/photo/material/batch_update_status', {
                                    codes: codes,
                                    status: v,
                                }, {
                                    loading: true,
                                } ).then( () => {
                                    loadData();
                                    message.success( '操作成功' );
                                } );
                            } }>
                                <Select.Option value="online">使用中</Select.Option>
                                <Select.Option value="underpick">待筛选</Select.Option>
                                <Select.Option value="offline">已下线</Select.Option>
                            </Select>

                        </Space>
                    </div>


                    <div style={ { float: 'right' } }>
                        <Pagination
                            current={ imgResult.page }
                            pageSize={ imgResult.limit }
                            total={ imgResult.total }
                            onChange={ ( page, limit ) => {
                                loadData( ( page - 1 ) * limit, limit );
                            } }
                        />
                    </div>
                </div>
            </div>


            <Modal
                title="批量打签"
                open={ isShowTagModal }
                height={ 400 }
                width={ 800 }
                onCancel={ () => {
                    setIsShowTagModal( false );
                } }
                footer={ [

                    <Button
                        key="copy_param"
                        type="primary"
                        onClick={ () => {
                            const codes = [];
                            Object.keys( checkMap ).forEach( ( k ) => {
                                if ( checkMap[ k ] ) {
                                    codes.push( k );
                                }
                            } );
                            const params = tagForm.getFieldsValue();
                            post( '/api/photo/material/batch_update_tag', {
                                codes,
                                tags: params.tags,
                            }, {
                                loading: true,
                            } ).then( () => {
                                setIsShowTagModal( false );
                                loadData();
                                message.success( '操作成功' );
                            } );
                        } }
                    >
                        确认
                    </Button>,
                    <Button
                        key="close"
                        onClick={ () => {
                            setIsShowTagModal( false );
                        } }
                    >
                        关闭
                    </Button>,
                ] }
            >
                <Form
                    form={ tagForm }
                    labelCol={ {
                        style: { width: 80 },
                    } }
                >

                    <Form.Item
                        name="tags"
                        label="标签"
                    >
                        <GroupTagSelect
                            param={
                                {
                                    foreground: 1,
                                    limit: 10000
                                }
                            }
                        />
                    </Form.Item>


                </Form>
            </Modal>

            < FormDrawer param={ formParam } setParam={ setFormParam } reload={ () => { loadData() } } />

        </div>
    );
};
addPage( '/photo/material/manager', Page );
addRoute( "/materialManager", Page );
export default Page;
