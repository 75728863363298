import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Select,
    Row,
    Col,
    Form,
    Input,
    Switch,
    Space,
    InputNumber,
    message
} from 'antd';
import ImageOssUpload from 'component/ImageOssUpload';
import AiImagePrompt from 'component/AiImagePrompt';
import GroupTagSelect from 'component/GroupTagSelect';

import { post } from 'util/http';
import SearchSelect from 'component/SearchSelect';


const Page = ( {
    param = {},
    setParam,
    reload
} ) => {

    const [ pageForm ] = Form.useForm();

    const updateFormTags = ( param ) => {
        const background_group_tags = param.background_group_tags;
        const foreground_group_tags = param.foreground_group_tags;
        const tags = [];
        for ( let key in background_group_tags ) {
            const tmpTags = background_group_tags[ key ];
            for ( let i = 0; i < tmpTags.length; i++ ) {
                if ( tags.indexOf( tmpTags[ i ] ) == -1 ) {
                    tags.push( tmpTags[ i ] );
                }
            }
        }
        for ( let key in foreground_group_tags ) {
            const tmpTags = foreground_group_tags[ key ];
            for ( let i = 0; i < tmpTags.length; i++ ) {
                if ( tags.indexOf( tmpTags[ i ] ) == -1 ) {
                    tags.push( tmpTags[ i ] );
                }
            }
        }
        pageForm.setFieldsValue( {
            tags: tags
        } );
    }

    useEffect( () => {


    }, [] )
    useEffect( () => {
        if ( param.visible ) {
            pageForm.resetFields();
            pageForm.setFieldsValue( param.data );
        }
    }, [ param ] )

    return ( <Drawer title="前景管理"
        placement="right"
        width={ 1160 }
        maskClosable={ false }
        onClose={ () => {
            setParam( { ...param, visible: false } )
        } }
        visible={ param.visible }
        extra={
            <Space>
                <Button
                    disabled={ param.code == null || param.code == '' }
                    type='danger' onClick={ () => {
                        post( '/api/photo/frame/delete', {
                            code: param.code,
                        }, {
                            loading: true,
                        } ).then( () => {
                            setParam( { ...param, visible: false } );
                            reload();
                        } );
                    } }>删除</Button>
                <Button onClick={ () => {
                    setParam( { ...param, visible: false } )
                } }>取消</Button>
                <Button type="primary" onClick={ () => {
                    pageForm.validateFields().then( ( data ) => {
                        post( '/api/photo/frame/save', {
                            ...data,
                        }, {
                            loading: true,
                        } ).then( () => {
                            setParam( { ...param, visible: false } );
                            reload();
                        } );
                    } )
                } }>
                    确认
                </Button>
            </Space>
        }
    >
        <Form
            form={ pageForm }
            labelCol={ {
                style: { width: 80 },
            } }
        >
            <Row>
                <Col span={ 6 } style={ {
                    display: param.code == null ? 'block' : 'none'
                } }>
                    <Form.Item name="prompt" >
                        <Input.TextArea placeholder='输入提示词由Ai生成相框' style={ { height: 150 } } />
                    </Form.Item>
                    <div style={ {
                        color: "#999",
                        fontSize: 12,
                        marginBottom: 10
                    } }>
                        例如：蓝色背景，可爱风格
                    </div>

                    <Form.Item
                        name="style_code"
                        label="规格"
                    >
                        <SearchSelect url='/api/photo/style/search'
                            placeholder='选择规格'
                            param={ { status: 'online' } } />
                    </Form.Item>
                    <Form.Item
                        name="dig"
                        label="元素层抠除"
                    >
                        <Select placeholder="元素层抠除" >
                            <Select.Option value={ false }>否</Select.Option>
                            <Select.Option value={ true }>是</Select.Option>
                        </Select>
                    </Form.Item>

                    <Space>

                        <Button onClick={ () => {
                            const style_code = pageForm.getFieldValue( 'style_code' );
                            if ( style_code == null || style_code == '' ) {
                                message.error( '请选择规格' );
                                return;
                            }
                            const prompt = pageForm.getFieldValue( 'prompt' );
                            if ( prompt == null || prompt == '' ) {
                                message.error( '请输入提示词' );
                                return;
                            }
                            post( '/api/photo/frame/image/create', {
                                style_code: style_code,
                                prompt: prompt,
                                dig: pageForm.getFieldValue( 'dig' ),
                            }, {
                                loading: true,
                            } ).then( ( res ) => {
                                const resData = res.data;
                                updateFormTags( resData );
                                const url = resData.src_url;
                                if ( url != '' ) {
                                    let src_url = pageForm.getFieldValue( 'src_url' );
                                    if ( src_url == null ) {
                                        src_url = [];
                                    }
                                    src_url.push( url );
                                    pageForm.setFieldsValue( {
                                        src_url: src_url
                                    } )
                                } else {
                                    message.error( '生成失败,未匹配相关标签' );
                                }
                            } );
                        } }>Ai随机生成</Button>

                    </Space>
                </Col>

                <Col span={ 18 }>
                    <Form.Item
                        name="code"
                        style={ { display: 'none' } }
                    >
                        <Input type='hidden' />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={ [ { required: true, message: '请输入名称' } ] }
                    >
                        <Input placeholder="关键字模糊匹配" />
                    </Form.Item>
                    <Form.Item
                        name="style_code"
                        label="规格"
                        rules={ [ { required: true, message: '请选择规格' } ] }
                    >
                        <SearchSelect url='/api/photo/style/search' param={ { status: 'online' } } />
                    </Form.Item>


                    <Form.Item
                        name="tags"
                        label="标签"
                        rules={ [ { required: true, message: '请选择标签' } ] }
                    >
                        <GroupTagSelect
                        />
                    </Form.Item>

                    <Form.Item
                        name="status"
                        label="状态"
                        rules={ [ { required: true, message: '请选择状态' } ] }
                    >
                        <Select style={ { width: 100 } } placeholder="状态">
                            <Select.Option value="online">使用中</Select.Option>
                            <Select.Option value="underpick">待筛选</Select.Option>
                            <Select.Option value="offline">已下线</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="src_url"
                        label="图片"
                        rules={ [ { required: true, message: '请生成或上传图片' } ] }
                    >
                        <ImageOssUpload
                            label='上传本地图片'
                            uploadData={ {} }
                            maxCount={ 20 }
                            readOnly={ param.code != null }
                            folder='frames/'
                            imageStyle={ {
                                maxHeight: 200,
                                maxWidth: 200,
                                marginBottom: 10
                            } }
                            display={ 'block' }
                            imageHeight={ 200 }
                        />
                    </Form.Item>

                </Col>

            </Row>


        </Form>


    </Drawer > )
}

export default Page;