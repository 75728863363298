/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react';
import { post } from 'util/http';
import module from 'util/module';


import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, Avatar, Space, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';


const { Header } = Layout;


function Page() {
  const [ topMenu, setTopMenu ] = useState( [] );

  const navigate = useNavigate();
  const location = useLocation();
  const [ loginInfo, setLoginInfo ] = useState( null );

  useEffect( () => {
    window.APP_CONFIG.headMenu && setTopMenu( JSON.parse( window.APP_CONFIG.headMenu ) );

    post( '/api/login-info', {} ).then( ( res ) => {
      window.LOGIN_INFO = res.data;
      setLoginInfo( res.data );
    } )
  }, [] );


  const goLogin = () => {
    if ( window.APP_CONFIG.loginUrl ) {
      window.location.href = window.APP_CONFIG.loginUrl;
    } else {
      navigate( '/login' );
    }
  };

  const menu = (
    <Menu
      onClick={ ( item ) => {
        if ( item.key == 'logout' ) {
          post( `/api/logout`, {} )
            .then( () => {
              if ( window.APP_CONFIG.loginUrl ) {
                window.location.href = window.APP_CONFIG.loginUrl;
              } else {
                window.location.href = '/login';
              }
            } );
        }
      } }
      items={ [
        {
          label: '退出登录',
          key: 'logout',
        },
      ] }
    />
  );


  return (
    <Header className="xcloud-header">
      <div
        className="logo"
        style={ { cursor: 'pointer' } }
        onClick={ () => {
          navigate( '/' );
        } }
      >
        { window.APP_CONFIG.logo ? ( <img src={ window.APP_CONFIG.logo } /> ) : <img src={ require( '../asserts/images/logo.png' ) } /> }
        { window.APP_CONFIG.appName ? window.APP_CONFIG.appName : '控制台' }
      </div>
      <Menu
        theme="light"
        mode="horizontal"
        selectedKeys={ [ location.pathname ] }
        items={ topMenu }
        onClick={ ( e ) => {
          if ( e.key.startsWith( 'http' ) ) {
            window.open( e.key );
          } else {
            navigate( e.key );
          }
        } }
        style={ { float: 'left' } }
      />

      <div style={ { float: 'right' } }>
        {
          loginInfo != null
          && <Dropdown overlay={ menu }>
            <Space style={ { cursor: 'pointer' } }>
              <span>{ loginInfo.name }</span>
              <DownOutlined />
            </Space>
          </Dropdown>
        }
        { loginInfo === null && <Button
          type="text"
          onClick={ () => {
            goLogin();
          } }
        >登录
        </Button> }
      </div>
    </Header>
  );
}
export default Page;
