import React, { } from 'react';

import { addRoute } from 'component/DynamicRotes';
import { Layout } from 'antd';

import AdminHead from './admin-head';
import AdminBody from './admin-body';


import './admin.css';

import './home/index';

import './photo/index'


function Page() {
  return (
    <Layout className="xcloud-wrapper">
      <AdminHead />
      <AdminBody />
    </Layout>
  );
}
addRoute( '*', Page );
export default Page;
