import React, { useState, useEffect } from 'react';
import { Button, Space, Form, Row, Pagination, Col, Image, Select, Input } from 'antd';
import { post, getParam, getImgUrl } from 'util/http';
import { addPage, addRoute } from 'component/DynamicRotes.js';
import FormDrawer from './form.js';

function Page() {
    const [ imgResult, setImgResult ] = useState( {
        limit: 12,
        page: 1,
        total: 0,
        records: [],
    } );
    const [ searchForm ] = Form.useForm();
    const [ formParam, setFormParam ] = useState( {} );


    const showLength = ( str, len ) => {
        if ( str == null ) {
            return '';
        } if ( str.length > len ) {
            return `${ str.substring( 0, len ) }...`;
        }
        return str;
    };

    const loadData = ( start = 0, limit = 24 ) => {
        const params = searchForm.getFieldsValue();
        post( '/api/photo/style/search', {
            ...params,
            start,
            limit,
        }, {
            loading: true,
        } ).then( ( res ) => {
            setImgResult( res.data );
        } );
    };

    useEffect( () => {
        searchForm.setFieldsValue( {
            tags: getParam( 'tags' ) ? getParam( 'tags' ).split( ',' ) : [],
            status: getParam( 'status' ) ? getParam( 'status' ) : 'online',
        } );
        loadData();
    }, [] );
    return (
        <div className="page-content">
            <div>

                <Form
                    form={ searchForm }
                    labelCol={ {
                    } }
                >
                    <Space>
                        <Form.Item
                            name="status"
                            style={ { marginBottom: 0 } }
                        >
                            <Select style={ { width: 100 } } placeholder="状态" onChange={ () => {
                                loadData();
                            } }>
                                <Select.Option value="online">使用中</Select.Option>
                                <Select.Option value="offline">已下线</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="searchName"
                            style={ { marginBottom: 0 } }
                        >
                            <Input placeholder="关键字模糊匹配" />
                        </Form.Item>
                        <Button
                            type="primary"
                            onClick={ () => {
                                loadData();
                            } }
                        >查询
                        </Button>

                        <Button style={ { marginLeft: 10 } } onClick={ () => {
                            setFormParam( {
                                visible: true,
                                data: {
                                    setting: "{}"
                                },
                            } );
                        } }>创建</Button>

                    </Space>


                </Form>

                <div style={ { clear: 'both', marginTop: 10 } }>

                    <Row gutter={ 10 }>
                        {
                            imgResult.records.map( img => (
                                <Col
                                    span={ 4 }
                                    key={ `img-${ img.id }` }
                                    style={ {
                                        padding: 10,
                                    } }
                                >
                                    <div style={ { textAlign: 'center' } }>
                                        <Image
                                            src={ getImgUrl( img.preview_img ) }
                                            style={ { maxWidth: 200, maxHeight: 200 } }
                                        />
                                    </div>
                                    <div style={ { textAlign: 'center', marginTop: 10 } }>
                                        <div>
                                            { showLength( img.name, 8 ) }
                                        </div>
                                        <Space>
                                            <Button
                                                size="small"
                                                onClick={ () => {
                                                    setFormParam( {
                                                        visible: true,
                                                        code: img.code,
                                                        data: {
                                                            ...img
                                                        }
                                                    } )
                                                } }
                                            >修改
                                            </Button>
                                            <Button
                                                size="small"
                                                onClick={ () => {
                                                    window.open( `/photo/style/design?code=${ img.code }` );
                                                } }
                                            >设计
                                            </Button>
                                        </Space>
                                    </div>
                                </Col>
                            ) )
                        }

                    </Row>


                    <div style={ { float: 'right' } }>
                        <Pagination
                            current={ imgResult.page }
                            pageSize={ imgResult.limit }
                            total={ imgResult.total }
                            onChange={ ( page, limit ) => {
                                loadData( ( page - 1 ) * limit, limit );
                            } }
                        />
                    </div>
                </div>
            </div>



            < FormDrawer param={ formParam } setParam={ setFormParam } reload={ () => { loadData() } } />

        </div>
    );
};
addPage( '/photo/style/manager', Page );
addRoute( "/styleManager", Page );
export default Page;
