import React from 'react';
import { Empty } from 'antd';
import { addPage } from 'component/DynamicRotes.js';

function Page() {
  return (
    <div>
      <Empty
        style={{ paddingTop: 200 }}
        description="首页"
      />
    </div>
  );
};
addPage('/home', Page);
export default Page;
