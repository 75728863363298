
import React, { createContext, useRef, useEffect, useContext } from 'react';
import { Image as KImage, Transformer } from 'react-konva';
import { getImgUrl } from 'util/http';
import { v4 as uuidv4 } from 'uuid';

const EditImageContext = createContext();

const EditImage = ( {
    id = uuidv4(),
    readonly = false,
    value = {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        src_url: "http://tool.xworld/materials/image/af7737c6-ef27-412e-b9a9-606035f0a117.png"
    },
    onChange
} ) => {
    const { selectId, setSelectId } = useContext( EditImageContext );

    useEffect( () => {
        setIsSelected( id === selectId );
    }, [ selectId ] );

    const [ isSelected, setIsSelected ] = React.useState( false );

    const img = new Image();
    img.src = getImgUrl( value.src_url );
    img.onload = () => {
        if ( value.width === 0 || value.height === 0 ) {
            onChange( {
                ...value,
                width: img.width,
                height: img.height,
            } )
        }
    }

    const shapeRef = useRef();
    const trRef = useRef();

    useEffect( () => {
        if ( !readonly && isSelected ) {
            trRef.current.nodes( [ shapeRef.current ] );
            trRef.current.getLayer().batchDraw();
        }
    }, [ isSelected ] );

    return (
        <>
            <KImage
                onMouseDown={ ( event ) => {
                    if ( readonly ) return;
                    setIsSelected( isSelected )
                    setSelectId(
                        id
                    )
                    event.cancelBubble = true;
                } }
                ref={ shapeRef }
                image={ img }
                x={ value.x }
                y={ value.y }
                width={ value.width }
                height={ value.height }
                draggable={ !readonly }
                onDragEnd={ ( e ) => {
                    onChange && onChange( {
                        ...value,
                        x: parseInt( e.target.x() ),
                        y: parseInt( e.target.y() ),
                    } );
                } }
                onTransformEnd={ ( e ) => {
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    node.scaleX( 1 );
                    node.scaleY( 1 );
                    onChange && onChange( {
                        ...value,
                        x: parseInt( node.x() ),
                        y: parseInt( node.y() ),
                        width: parseInt( node.width() * scaleX ),
                        height: parseInt( node.height() * scaleY ),
                    } );
                } }
            />
            { !readonly && isSelected && <Transformer ref={ trRef } onMouseDown={ ( event ) => {
                event.cancelBubble = true;

            } } /> }
        </>
    );
};
export { EditImageContext };
export default EditImage;