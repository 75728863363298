import React, { useState, useEffect } from 'react';
import { Form, Row, Pagination, Col, Image, Button, Space } from 'antd';
import { post, getParam, getImgUrl } from 'util/http';
import GroupTagSelect from 'component/GroupTagSelect';
import Search from 'antd/lib/input/Search';
import FormDrawer from './form.js';

function Page( {
    alfterSelect,
} ) {
    const [ imgResult, setImgResult ] = useState( {
        limit: 12,
        page: 1,
        total: 0,
        records: [],
    } );
    const [ searchForm ] = Form.useForm();
    const [ formParam, setFormParam ] = useState( {} );


    const loadData = ( start = 0, limit = 24 ) => {
        const params = searchForm.getFieldsValue();
        post( '/api/photo/foreground/search', {
            ...params,
            status: 'online',
            start,
            limit,
        }, {
            loading: true,
        } ).then( ( res ) => {
            setImgResult( res.data );
        } );
    };

    useEffect( () => {
        searchForm.setFieldsValue( {
            tags: getParam( 'tags' ) ? getParam( 'tags' ).split( ',' ) : [],
        } );
        loadData();
    }, [] );
    return (
        <div style={ {
            position: 'relative',
            height: window.innerHeight - 80,
        } }>
            <div>

                <Form
                    form={ searchForm }
                    labelCol={ {
                    } }

                >
                    <div style={ {
                        display: 'flex',
                    } }>
                        <Form.Item
                            name="searchName"
                        >
                            <Search placeholder="关键字模糊匹配" style={ { width: 280 } } />
                        </Form.Item>

                        <Button style={ { marginLeft: 10 } } onClick={ () => {
                            setFormParam( {
                                visible: true,
                                title: '上传图片',
                                data: {}
                            } );
                        } }>创建背景</Button>

                    </div>
                    <Form.Item
                        name="tags"
                        style={ { marginBottom: 0 } }
                    >
                        <GroupTagSelect
                            param={
                                {
                                    background: 1,
                                    limit: 10000
                                }
                            }
                            onChange={ ( v ) => {
                                loadData();
                            } }
                        />
                    </Form.Item>


                </Form>


                <div style={ { clear: 'both', marginTop: 10 } }>

                    <Row gutter={ 10 }>
                        {
                            imgResult.records.map( img => (
                                <Col
                                    span={ 4 }
                                    key={ `img-${ img.id }` }
                                    style={ {
                                        padding: 10,
                                        textAlign: 'center',
                                        alignItems: 'center',
                                        verticalAlign: 'center'
                                    } }
                                >
                                    <Image
                                        preview={ false }
                                        src={ getImgUrl( img.src_url + "?x-oss-process=image/resize,w_50" ) }
                                        style={ { maxWidth: 50, maxHeight: 50, cursor: 'pointer' } }
                                        onClick={ () => {
                                            if ( alfterSelect ) {
                                                alfterSelect( img );
                                            }

                                        } }
                                    />
                                </Col>
                            ) )
                        }

                    </Row>


                    <div style={ { float: 'right' } }>
                        <Pagination
                            current={ imgResult.page }
                            pageSize={ imgResult.limit }
                            total={ imgResult.total }
                            onChange={ ( page, limit ) => {
                                loadData( ( page - 1 ) * limit, limit );
                            } }
                        />
                    </div>

                </div>

                <div style={ { marginTop: 60 } }>

                    <Button type='default' block onClick={ ( e ) => {
                        alfterSelect( {
                            src_url: ''
                        } );
                    } }>清除前景</Button>
                </div>

            </div>

            < FormDrawer param={ formParam } setParam={ setFormParam } reload={ () => { loadData() } } />



        </div>
    );
};

export default Page;
