import React, { useEffect, useState } from 'react';

import { DynamicPages } from 'component/DynamicRotes';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu, Breadcrumb } from 'antd';


const { Content, Sider } = Layout;


const ALL_MENU_MAP = {
};

function Page() {
  const [ menuData, updateMenuData ] = useState( {
    items: [],
    selectedKeys: [],
    openKeys: [],
  } );

  const [ breadcrumbData, setBreadcrumbData ] = useState( [] );
  const navigate = useNavigate();
  const location = useLocation();

  const updateBreadcrumbData = ( keyPath ) => {
    const newData = [];
    for ( let i = keyPath.length - 1; i >= 0; i-- ) {
      const m = ALL_MENU_MAP[ keyPath[ i ] ];
      if ( m != null ) {
        newData.push( m.label );
      }
    }
    setBreadcrumbData( newData );
  };

  const loadMenuTree = ( data, menuInitData, parentUrls ) => {
    const res = {
      key: `${ data.url }`,
      label: data.name,
    };
    ALL_MENU_MAP[ res.key ] = res;
    if ( data.children != null && data.children.length > 0 ) {
      res.children = [];
      for ( const i in data.children ) {
        res.children.push( loadMenuTree( data.children[ i ], menuInitData, [ ...parentUrls, data.url ] ) );
      }
    }
    if ( data.url === location.pathname ) {
      menuInitData.selectedKeys = [];
      menuInitData.selectedKeys.push( data.url );
      menuInitData.openKeys = parentUrls;
    }
    return res;
  };

  useEffect( () => {
    const metuData = [
      {
        name: '相框工具',
        url: '/xiangkuang',
        parentCode: '-1',
        showOrder: -10,
        icon: '',
        children: [ {
          name: '标签管理',
          url: '/photo/tag/manager',
          showOrder: 12,
          icon: '',
        }, {
          name: '素材管理',
          url: '/photo/material/manager',
          showOrder: 12,
          icon: '',
        }, {
          name: '规格管理',
          url: '/photo/style/manager',
          showOrder: 12,
          icon: '',
        }, {
          name: '前景管理',
          url: '/photo/foreground/manager',
          showOrder: 12,
          icon: '',
        }, {
          name: '背景管理',
          url: '/photo/background/manager',
          showOrder: 12,
          icon: '',
        }, {
          name: '相框管理',
          url: '/photo/frame/manager',
          showOrder: 12,
          icon: '',
        }, {
          name: '换脸',
          url: '/photo/face/change',
          showOrder: 12,
          icon: '',
        } ],
      }
    ];
    const menuInitData = {
      items: [],
      selectedKeys: [ '/photo/tag/manager' ],
      openKeys: [ '/xiangkuang' ],
    };
    metuData.map( ( item ) => {
      const t = loadMenuTree( item, menuInitData, [] );
      menuInitData.items.push( t );
    } );
    updateMenuData( menuInitData );
  }, [] );

  useEffect( () => {
    updateBreadcrumbData( [ location.pathname ] );
  }, [ location.pathname ] );


  return (
    <Layout className="xcloud-body">
      <Sider
        width={ 200 }
        className="site-layout-background"
        style={ {
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 64,
          bottom: 0,
        } }
      >
        <Menu
          mode="inline"
          selectedKeys={ menuData.selectedKeys }
          openKeys={ menuData.openKeys }
          style={ {
            height: '100%',
            borderRight: 0,
          } }
          onOpenChange={ ( e ) => {
            updateMenuData( {
              ...menuData,
              openKeys: e,
            } );
          } }
          onClick={ ( e ) => {
            updateMenuData( {
              ...menuData,
              selectedKeys: e.keyPath,
              openKeys: e.keyPath,
            } );
            if ( e.key === '/photo/frame/design' ) {
              window.open( e.key );
            } else {
              navigate( e.key );
            }
          } }
          items={ menuData.items }
        />
      </Sider>
      <Layout className="xcloud-content-wrapper">
        <Breadcrumb className="breadcrumb">
          {
            breadcrumbData.map( item => ( <Breadcrumb.Item key={ item }>{ item }</Breadcrumb.Item> ) )
          }
        </Breadcrumb>
        <Content
          className="xcloud-content"
          id="xcloud-content"
        >
          <DynamicPages />
        </Content>
      </Layout>
    </Layout>
  );
}
export default Page;
