import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Form,
    Input,
    InputNumber,
    Select,
    Space
} from 'antd';
import { post } from 'util/http';

const Page = ( {
    param,
    setParam,
    reload
} ) => {


    const [ pageForm ] = Form.useForm();

    useEffect( () => {

    }, [] )
    useEffect( () => {
        if ( param.visible ) {
            if ( param.data ) {
                pageForm.resetFields();
                pageForm.setFieldsValue( param.data );
            } else {
                pageForm.resetFields();
            }
        }


    }, [ param ] )

    return ( <Drawer title="标签管理"
        placement="right"
        width={ 500 }
        maskClosable={ false }
        onClose={ () => {
            setParam( { ...param, visible: false } )
        } }
        visible={ param.visible }
        extra={
            <Space>
                <Button onClick={ () => {
                    setParam( { ...param, visible: false } )
                } }>取消</Button>
                <Button type="primary" onClick={ () => {
                    pageForm.validateFields().then( ( data ) => {
                        post( '/api/photo/tag/save', {
                            ...data,
                        }, {
                            loading: true,
                        } ).then( () => {
                            setParam( { ...param, visible: false } );
                            reload();
                        } );
                    } )
                } }>
                    确认
                </Button>
            </Space>
        }
    >
        <Form form={ pageForm } layout="horizontal" hideRequiredMark
            labelCol={ {
                style: { width: 60 }
            } }
            initialValues={ {
                attrs: []
            } }
        >
            <Form.Item style={ { display: 'none' } }
                name="code">
                <Input type="hidden" />
            </Form.Item>
            <Form.Item label="类别" name="group_name" rules={ [ { required: true, message: '请输入类别' } ] }>
                <Input />
            </Form.Item>
            <Form.Item label="名称" name="name" rules={ [ { required: true, message: '请输入名称' } ] }>
                <Input />
            </Form.Item>
            <Form.Item label="前景" name="foreground" rules={ [ { required: true, message: '请选择是否前景标签' } ] } >
                <Select>
                    <Select.Option value={ 1 }>是</Select.Option>
                    <Select.Option value={ 0 }>否</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item label="背景" name="background" rules={ [ { required: true, message: '请选择是否背景标签' } ] }>
                <Select>
                    <Select.Option value={ 1 }>是</Select.Option>
                    <Select.Option value={ 0 }>否</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item label="排序" name="show_order" rules={ [ { required: true, message: '请输入排序' } ] }>
                <InputNumber style={ { width: '100%' } } />
            </Form.Item>

        </Form>
    </Drawer> )
}

export default Page;