import React, { Routes, Route } from 'react-router-dom';

global.allRoutes = global.allRoutes || {};
global.allPages = global.allPages || {};
export function addRoute(path, element) {
  global.allRoutes = global.allRoutes || {};
  global.allRoutes[path] = element;
}
export function addPage(path, element) {
  global.allPages = global.allPages || {};
  if (window.APP_CONFIG.mainPath != null && path == window.APP_CONFIG.mainPath) {
    global.allPages['*'] = element;
  }
  global.allPages[path] = element;
}


function DynamicRoute({
  path,
}) {
  global.allRoutes = global.allRoutes || {};
  if (global.allRoutes[path]) {
    const Element = global.allRoutes[path];
    return <Element />;
  }
  return (<div>路由不存在</div>);
};

function DynamicPage({
  path,
}) {
  global.allPages = global.allPages || {};
  if (global.allPages[path]) {
    const Element = global.allPages[path];
    return <Element />;
  }
  return (<div>页面不存在</div>);
};

function DynamicRoutes() {
  return (
    <Routes>
      {
      Object.keys(global.allRoutes).map(key => (
        <Route path={key} element={<DynamicRoute path={key} />} key={key}> </Route>
      ))
        }
    </Routes>
  );
};


export function DynamicPages() {
  return (
    <Routes>
      {
      Object.keys(global.allPages).map(key => (
        <Route path={key} element={<DynamicPage path={key} />} key={key} />))
        }
    </Routes>
  );
};


export default DynamicRoutes;
