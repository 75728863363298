import React, { useState } from 'react';
import { Button, Select } from 'antd';
import 'antd/dist/antd.css';
import TextArea from 'antd/lib/input/TextArea';
import { post } from 'util/http';

function AiImagePrompt( {
  value,
  onChange,
  removeBg = false,
  widthOptions = [ 1920 / 4, 2860 / 4 ],
  heightOptions = [ 1920 / 4, 2860 / 4 ],
  defaultWidth = 1920 / 4,
  defaultHeight = 2860 / 4,
  defaultUpsacle = 4,
  defaultModel = "realisticVisionV20_v20.safetensors",
  url = '/api/photo/material/ai/create',
  afterCreate,
  ...nextProps
} ) {
  const [ width, setWidth ] = useState( defaultWidth );
  const [ height, setHeight ] = useState( defaultHeight );
  const [ upScale, setUpScale ] = useState( defaultUpsacle );
  const [ model, setModel ] = useState( defaultModel );
  const [ translate, setTranslate ] = useState( false )


  return ( <div>
    <TextArea value={ value } onChange={ onChange } { ...nextProps } placeholder='输入提示词由Ai生成图片' rows={ 6 } />
    {/* <div style={ { marginTop: 10 } }>
      <Select defaultValue={ false } onChange={ ( e ) => {
        setTranslate( e );
      } }>
        <Select.Option value={ false }>不自动翻译</Select.Option>
        <Select.Option value={ true }>自动翻译</Select.Option>
      </Select>
    </div> */}
    <div style={ { marginTop: 10 } }>

      <Select defaultValue={ defaultModel } onChange={ ( e ) => {
        setModel( e );
      } }>
        <Select.Option value="realisticVisionV20_v20.safetensors">真实人物摄影</Select.Option>
        <Select.Option value="animeBackgrounds_10.ckpt">动漫背景风格</Select.Option>
        <Select.Option value="aniflatmixAnimeFlatColorStyle_v20.safetensors">平涂风动画图片</Select.Option>
        <Select.Option value="landscapeRealistic_v20WarmColor.safetensors">风景写实</Select.Option>
        {/* <Select.Option value="AbyssOrangeMix2_sfw.safetensors">深渊橘</Select.Option>
        <Select.Option value="majicmixRealistic_v6.safetensors">麦橘写实</Select.Option>
        <Select.Option value="Guofeng3 V32.ckpt">国风</Select.Option> */}
      </Select>
    </div>
    <div style={ { marginTop: 10 } }>
      <Select placeholder="宽" style={ { width: 100 } } defaultValue={ defaultWidth } onChange={ ( v ) => {
        setWidth( v );
      } }>
        { widthOptions.map( ( item ) => <Select.Option key={ item } value={ item }>{ item }</Select.Option> ) }
      </Select>
      &nbsp;x&nbsp;
      <Select placeholder="高" style={ { width: 100 } } defaultValue={ defaultHeight } onChange={ ( v ) => {
        setHeight( v );
      } }>
        { heightOptions.map( ( item ) => <Select.Option key={ item } value={ item }>{ item }</Select.Option> ) }
      </Select>
    </div>
    <div style={ { marginTop: 10 } }>
      <Select placeholder="倍数" style={ { width: 100 } } defaultValue={ upScale } onChange={ ( v ) => {
        setUpScale( v );
      } }>
        <Select.Option key={ 1 } value={ 1 }>1倍</Select.Option>
        <Select.Option key={ 2 } value={ 2 }>2倍</Select.Option>
        <Select.Option key={ 3 } value={ 3 }>3倍</Select.Option>
        <Select.Option key={ 4 } value={ 4 }>4倍</Select.Option>
        <Select.Option key={ 5 } value={ 5 }>5倍</Select.Option>
        <Select.Option key={ 6 } value={ 6 }>3倍</Select.Option>

      </Select>
    </div>
    <div style={ { marginTop: 10 } }>
      实际：{ width * upScale } x { height * upScale }
    </div>
    <Button onClick={ () => {
      post( url, {
        remove_bg: removeBg,
        prompt: value,
        width,
        height,
        up_scale: upScale,
        model: model,
        translate: translate
      }, {
        loading: true,
      } ).then( ( res ) => {
        if ( afterCreate ) {
          afterCreate( res.data, value );
        }
      } );
    } } style={ { marginTop: 10 } }>生成图片</Button>
  </div> );
};

export default AiImagePrompt;
