import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { post } from 'util/http';

function SearchSelect( {
  url,
  value,
  param,
  labelCode = 'name',
  valueCode = 'code',
  onChange,
  showSearch = false,
  optionDatas = [],
  labelInValue = false,
  mode,
  ...nextProps
} ) {
  const [ options, setOptions ] = useState( [] );

  const getValue = ( item, code ) => {
    if ( typeof labelCode === 'string' ) {
      return item[ code ];
    } if ( code instanceof Array ) {
      let newVal = item;
      code.forEach( ( i ) => {
        if ( newVal != null ) {
          newVal = newVal[ i ];
        }
      } );
      return newVal;
    }
    return item[ code ];
  };

  const loadData = ( newParam ) => {
    post( url, { ...param, ...newParam } )
      .then( ( res ) => {
        const records = res.data.records;
        const newOptions = records.map( item => ( {
          label: getValue( item, labelCode ),
          value: getValue( item, valueCode ),
          data: item,
        } ) );
        setOptions( [ ...optionDatas, ...newOptions ] );
      } );
  };
  useEffect( () => {
    loadData();
  }, [ param ] );

  return ( <Select
    value={ value }
    onChange={ onChange }
    mode={ mode }
    labelInValue={ labelInValue }
    options={ options }
    showSearch={ showSearch }
    onSearch={ ( v ) => {
      if ( showSearch ) {
        loadData( { searchName: v } );
      }
    } }
    allowClear
    { ...nextProps }
  /> );
};

export default SearchSelect;
