import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Select,
    Row,
    Col,
    Form,
    Input,
    Space,
    InputNumber
} from 'antd';
import ImageOssUpload from 'component/ImageOssUpload';
import AiImagePrompt from 'component/AiImagePrompt';
import GroupTagSelect from 'component/GroupTagSelect';
import SearchSelect from 'component/SearchSelect';

import { post } from 'util/http';


const Page = ( {
    param = {},
    setParam,
    reload
} ) => {

    const [ pageForm ] = Form.useForm();


    useEffect( () => {


    }, [] )
    useEffect( () => {
        if ( param.visible ) {
            pageForm.resetFields();
            if ( param.code ) {
                post( '/api/photo/background/get', {
                    code: param.code,
                } ).then( ( res ) => {
                    const resData = res.data;
                    resData.tags = resData.tags.split( ',' );
                    pageForm.setFieldsValue( resData );
                } );
            }
        }
    }, [ param ] )

    return ( <Drawer title="背景管理"
        placement="right"
        width={ 1160 }
        maskClosable={ false }
        onClose={ () => {
            setParam( { ...param, visible: false } )
        } }
        visible={ param.visible }
        extra={
            <Space>
                <Button
                    disabled={ param.code == null || param.code == '' }
                    type='danger' onClick={ () => {
                        post( '/api/photo/background/delete', {
                            code: param.code,
                        }, {
                            loading: true,
                        } ).then( () => {
                            setParam( { ...param, visible: false } );
                            reload();
                        } );
                    } }>删除</Button>
                <Button onClick={ () => {
                    setParam( { ...param, visible: false } )
                } }>取消</Button>
                <Button type="primary" onClick={ () => {
                    pageForm.validateFields().then( ( data ) => {
                        post( '/api/photo/background/save', {
                            ...data,
                        }, {
                            loading: true,
                        } ).then( () => {
                            setParam( { ...param, visible: false } );
                            reload();
                        } );
                    } )
                } }>
                    确认
                </Button>
            </Space>
        }
    >
        <Form
            form={ pageForm }
            labelCol={ {
                style: { width: 80 },
            } }
        >
            <Row>
                <Col span={ 6 } style={ {
                    display: param.code == null ? 'block' : 'none'
                } }>
                    <div >

                        <Form.Item
                            name="prompt"
                            label=""
                        >
                            <AiImagePrompt
                                reduceScale={ 4 }
                                url='/api/photo/background/ai/create'
                                afterCreate={ ( data, prompt ) => {
                                    let src_url = pageForm.getFieldValue( 'src_url' );
                                    if ( src_url == null ) {
                                        src_url = [];
                                    }
                                    src_url.push( data.url );
                                    pageForm.setFieldsValue( {
                                        src_url: src_url
                                    } )

                                } } />
                        </Form.Item>
                    </div>
                </Col>
                <Col span={ 16 }>

                    <Form.Item
                        name="code"
                        style={ { display: 'none' } }
                    >
                        <Input type='hidden' />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={ [ { required: true, message: '请输入名称' } ] }
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="style_codes"
                        label="规格"
                        rules={ [ { required: true, message: '请选择规格' } ] }
                    >
                        <SearchSelect url='/api/photo/style/search' mode={ "tags" } param={ {
                            status: 'online'
                        } } />
                    </Form.Item>
                    <Form.Item
                        name="tags"
                        label="标签"
                        rules={ [ { required: true, message: '请选择标签' } ] }
                    >
                        <GroupTagSelect
                            param={
                                {
                                    background: 1,
                                    limit: 10000
                                }
                            }
                            onChange={ ( value ) => {
                                console.log( value )
                            } }
                        />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="状态"
                        rules={ [ { required: true, message: '请选择状态' } ] }
                    >
                        <Select style={ { width: 100 } } placeholder="状态" rules={ [ { required: true, message: '请选择状态' } ] } >
                            <Select.Option value="online">使用中</Select.Option>
                            <Select.Option value="underpick">待筛选</Select.Option>
                            <Select.Option value="offline">已下线</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="src_url"
                        label="图片"
                        rules={ [ { required: true, message: '请生成或上传图片' } ] }
                    >
                        <ImageOssUpload
                            label='上传本地图片'
                            uploadData={ {} }
                            previewImg={ true }
                            folder='backgrounds/'
                            maxCount={ 20 }
                            imageStyle={ {
                                maxHeight: 50,
                                maxWidth: 50,
                                marginBottom: 10
                            } }
                            display={ 'block' }
                            imageHeight={ 200 }
                        />
                    </Form.Item>


                </Col>
            </Row>
        </Form>


    </Drawer > )
}

export default Page;