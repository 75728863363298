import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Select,
    Row,
    Col,
    Form,
    Input,
    Switch,
    Space,
} from 'antd';
import ImageOssUpload from 'component/ImageOssUpload';
import AiImagePrompt from 'component/AiImagePrompt';
import GroupTagSelect from 'component/GroupTagSelect';

import { post } from 'util/http';


const Page = ( {
    param = {},
    setParam,
    reload
} ) => {

    const [ pageForm ] = Form.useForm();
    const [ removeBg, setRemoveBg ] = useState( false );

    useEffect( () => {


    }, [] )
    useEffect( () => {
        if ( param.visible ) {
            pageForm.resetFields();
            if ( param.code ) {
                post( '/api/photo/material/get', {
                    code: param.code,
                } ).then( ( res ) => {
                    const resData = res.data;
                    resData.tags = resData.tags.split( ',' );
                    pageForm.setFieldsValue( resData );
                } );
            }
        }
    }, [ param ] )

    return ( <Drawer title="素材管理"
        placement="right"
        width={ 1160 }
        maskClosable={ false }
        onClose={ () => {
            setParam( { ...param, visible: false } )
        } }
        visible={ param.visible }
        extra={
            <Space>
                <Button onClick={ () => {
                    setParam( { ...param, visible: false } )
                } }>取消</Button>
                <Button type="primary" onClick={ () => {
                    pageForm.validateFields().then( ( data ) => {
                        post( '/api/photo/material/save', {
                            ...data,
                        }, {
                            loading: true,
                        } ).then( () => {
                            setParam( { ...param, visible: false } );
                            reload();
                        } );
                    } )
                } }>
                    确认
                </Button>
            </Space>
        }
    >
        <Form
            form={ pageForm }
            labelCol={ {
                style: { width: 80 },
            } }
        >
            <Row>
                <Col span={ 6 } style={ {
                    display: param.code == null ? 'block' : 'none'
                } }>
                    <div >
                        <Form.Item
                            name="removeBg"
                            label="自动抠图"
                        >
                            <Switch onChange={ ( e ) => {
                                setRemoveBg( e );
                            } } />
                        </Form.Item>


                        <Form.Item
                            name="prompt"
                            label=""
                        >
                            <AiImagePrompt
                                widthOptions={ [ 300, 400, 500 ] }
                                heightOptions={ [ 300, 400, 500 ] }
                                defaultUpsacle={ 1 }
                                defaultHeight={ 400 }
                                defaultWidth={ 400 }
                                removeBg={ removeBg }

                                afterCreate={ ( data, prompt ) => {
                                    let src_url = pageForm.getFieldValue( 'src_url' );
                                    if ( src_url == null ) {
                                        src_url = [];
                                    }
                                    src_url.push( data.url );
                                    pageForm.setFieldsValue( {
                                        src_url: src_url
                                    } )
                                } } />
                        </Form.Item>
                    </div>
                </Col>
                <Col span={ 16 }>

                    <Form.Item
                        name="code"
                        style={ { display: 'none' } }
                    >
                        <Input type='hidden' />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={ [ { required: true, message: '请输入名称' } ] }
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="tags"
                        label="标签"
                        rules={ [ { required: true, message: '请选择标签' } ] }
                    >
                        <GroupTagSelect
                            param={
                                {
                                    foreground: 1,
                                    limit: 10000
                                }
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="状态"
                        rules={ [ { required: true, message: '请选择状态' } ] }
                    >
                        <Select style={ { width: 100 } } placeholder="状态" >
                            <Select.Option value="online">使用中</Select.Option>
                            <Select.Option value="underpick">待筛选</Select.Option>
                            <Select.Option value="offline">已下线</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="src_url"
                        label="图片"
                        rules={ [ { required: true, message: '请上传图片或者生成图片' } ] }
                    >
                        <ImageOssUpload
                            label='批量上传本地图片'
                            folder='materials/'
                            maxCount={ 20 }
                            readOnly={ param.code != null }
                            uploadData={ { remove_bg: removeBg } }
                            imageStyle={ {
                                maxHeight: 150,
                                maxWidth: 150,
                                marginBottom: 10
                            } }
                            display={ 'block' }
                            previewImg={ true }
                            imageHeight={ 200 }
                            afterUpload={ () => {

                            } }
                        />
                    </Form.Item>

                </Col>
            </Row>
        </Form>


    </Drawer > )
}

export default Page;