import React, { useState, useEffect, useRef } from 'react';
import { Tag, Input, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { post } from 'util/http';

function GroupTagSelect( {
  value = [],
  onChange,
  options = [],
  addTag = false,
  param = {
    limit: 10000
  },
  ...nextProps
} ) {
  const inputRef = useRef( null );

  const [ groupOptions, setGroupOptions ] = useState( [] );
  const [ isEdit, setIsEdit ] = useState( false );
  const [ newTagValue, setNewTagValue ] = useState( '' );


  const toGroupValues = ( values ) => {
    const newGroupOptionMap = {};
    for ( let i = 0; i < groupOptions.length; i++ ) {
      const group = groupOptions[ i ];
      for ( let j = 0; j < group.options.length; j++ ) {
        const option = group.options[ j ];
        if ( values.indexOf( option.value ) > -1 ) {
          if ( !newGroupOptionMap[ group.label ] ) {
            newGroupOptionMap[ group.label ] = [];
          }
          newGroupOptionMap[ group.label ].push( option.value );
        }
      }
    }
    return newGroupOptionMap;
  }
  const updateGroupOptions = ( options ) => {
    const groupMap = {};
    for ( let i = 0; i < options.length; i++ ) {
      const option = options[ i ];
      const group = option.group;
      if ( group ) {
        if ( !groupMap[ group ] ) {
          groupMap[ group ] = [];
        }
        groupMap[ group ].push( option );
      }
    }
    const newGroupOptions = [];
    for ( let key in groupMap ) {
      newGroupOptions.push( { label: key, options: groupMap[ key ] } );
    }
    setGroupOptions( newGroupOptions );
  }

  useEffect( () => {

    post( '/api/photo/tag/search', param ).then( ( res ) => {
      const records = res.data.records;
      if ( records.length > 0 ) {
        const newOptions = records.map( item => ( {
          label: item.name,
          value: item.name,
          group: item.group_name,
        } ) );
        updateGroupOptions( newOptions );
      }
    } );
  }, [] )



  useEffect( () => {
    if ( options.length === 0 ) {
      return;
    }
    updateGroupOptions( options );

  }, [ options ] )

  const saveNewTagValue = ( editMore ) => {
    if ( newTagValue ) {
      let hasExist = false;
      for ( let i = 0; i < value.length; i++ ) {
        if ( value[ i ] === newTagValue ) {
          hasExist = true;
          break;
        }
      }
      if ( !hasExist ) {
        onChange( [ ...value, newTagValue ] );
      }
      setNewTagValue( '' );
      setIsEdit( editMore );
      if ( editMore ) {
        inputRef.current.focus();
      }
    } else {
      setIsEdit( false );
    }

  }

  return ( <div>

    { groupOptions.map( ( group, index ) => {
      return <div key={ index } style={ { marginBottom: 0 } }>
        <Row>
          <Col flex="50px">
            <span>{ group.label }：</span>
          </Col>
          <Col flex="1">
            { group.options.map( ( option, index ) => {
              return <Tag
                style={ { marginBottom: 10 } }
                closable={ ( addTag && option.group == 'other' ) }
                className='photo-tag-select'
                key={ option.value }
                color={ value.indexOf( option.value ) > -1 ? "#108ee9" : "" }
                onClose={ () => {
                  onChange( value.filter( item => item !== option.value ) );
                } }
                onClick={ () => {
                  if ( value.indexOf( option.value ) > -1 ) {
                    const newValue = value.filter( item => item !== option.value );
                    onChange( newValue, toGroupValues( newValue ) );
                  } else {
                    const newValue = [ ...value, option.value ];
                    onChange( newValue, toGroupValues( newValue ) );
                  }
                } }
              >{ option.label }</Tag>
            } ) }
          </Col>
        </Row>


      </div>

    } ) }
    <div style={ { marginLeft: 50 } }>
      { value.map( ( item, index ) => {
        return <Tag
          closable={ addTag }
          style={ { marginBottom: 5 } }
          className='photo-tag-select'
          key={ item }
          color={ value.indexOf( item ) > -1 ? "#108ee9" : "" }
          onClose={ () => {
            onChange( value.filter( i => i !== item ) );
          } }
          onClick={ () => {
            if ( value.indexOf( item ) > -1 ) {
              onChange( value.filter( i => i !== item ) );
            } else {
              onChange( [ ...value, item ] );
            }
          } }
        >{ item }</Tag>
      } ) }
    </div>

    { addTag && !isEdit && <Tag
      className='photo-tag-select'
      style={ {
        height: 22,
        borderStyle: 'dashed',
      } } icon={ <PlusOutlined /> } onClick={ () => {
        setIsEdit( true );
      } }>
      新增标签
    </Tag> }
    { addTag && isEdit && <Input
      type="text"
      size="small"
      ref={ inputRef }
      style={ {
        width: 64,
        height: 22,
        marginInlineEnd: 8,
        verticalAlign: 'top',
      } }
      value={ newTagValue }
      onChange={ ( v ) => setNewTagValue( v.target.value ) }
      onBlur={ () => {
        saveNewTagValue( true );
      } }
      onPressEnter={ () => {
        saveNewTagValue( false );
      } }
    /> }
  </div> );
};

export default GroupTagSelect;
