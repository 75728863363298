import React, { useEffect, useState } from 'react'
import {
    Button,
    Drawer,
    Select,
    Row,
    Col,
    Form,
    Input,
    Steps,
    Space,
    InputNumber
} from 'antd';
import ImageOssUpload from 'component/ImageOssUpload';
import AiImagePrompt from 'component/AiImagePrompt';
import GroupTagSelect from 'component/GroupTagSelect';

import { post } from 'util/http';
import SearchSelect from 'component/SearchSelect';


const Page = ( {
    param = {},
    setParam,
    reload
} ) => {

    const [ formData, setFormData ] = useState( {} )
    const [ pageForm ] = Form.useForm();
    const [ prompt, setPrompt ] = useState( '' );



    useEffect( () => {


    }, [] )
    useEffect( () => {
        if ( param.visible ) {
            pageForm.resetFields();
            if ( param.code ) {
                post( '/api/photo/foreground/get', {
                    code: param.code,
                } ).then( ( res ) => {
                    const resData = res.data;
                    resData.tags = resData.tags.split( ',' );
                    pageForm.setFieldsValue( resData );
                    setFormData( resData )
                } );
            }

        }
    }, [ param ] )

    return ( <Drawer title="前景管理"
        placement="right"
        width={ 1160 }
        maskClosable={ false }
        onClose={ () => {
            setParam( { ...param, visible: false } )
        } }
        visible={ param.visible }
        extra={
            <Space>
                <Button
                    disabled={ param.code == null || param.code == '' }
                    type='danger' onClick={ () => {
                        post( '/api/photo/foreground/delete', {
                            code: param.code,
                        }, {
                            loading: true,
                        } ).then( () => {
                            setParam( { ...param, visible: false } );
                            reload();
                        } );
                    } }>删除</Button>
                <Button onClick={ () => {
                    setParam( { ...param, visible: false } )
                } }>取消</Button>
                <Button type="primary" onClick={ () => {
                    pageForm.validateFields().then( ( data ) => {
                        post( '/api/photo/foreground/save', {
                            ...data,
                        }, {
                            loading: true,
                        } ).then( () => {
                            setParam( { ...param, visible: false } );
                            reload();
                        } );
                    } )
                } }>
                    确认
                </Button>
            </Space>
        }
    >


        <Form
            form={ pageForm }
            style={ {
                minHeight: 400,
            } }
            labelCol={ {
                style: { width: 80 },
            } }
        >

            <Row gutter={ 10 }>
                {/* <Col span={ 6 } style={ {
                    display: formData.code == null ? 'block' : 'none'
                } }>
                    <Form.Item name="prompt" >
                        <Input.TextArea placeholder='输入提示词由Ai系统生成' style={ { height: 150 } } onChange={ ( e ) => {
                            setPrompt( e.target.value )
                        } } />
                    </Form.Item>
                    <div style={ {
                        color: "#999",
                        fontSize: 12,
                        marginBottom: 10
                    } }>
                        例如：每张前景图包含5个素材图片，每个素材图片的标签为：人物、动物、植物、建筑、风景
                    </div>
                    <Button onClick={ () => {
                        post( '/api/photo/foreground/image/create', {
                            style_code: "b63bb52614bc4184adf0105be2c79da9",
                            prompt: prompt
                        }, {
                            loading: true,
                        } ).then( ( res ) => {
                            pageForm.setFieldsValue( {
                                src_url: [ res.data.src_url ]
                            } )
                        } );
                    } }>生成图片</Button>
                </Col> */}
                <Col span={ 18 }>
                    <Form.Item
                        name="code"
                        style={ { display: 'none' } }
                    >
                        <Input type='hidden' />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="名称"
                        rules={ [ { required: true, message: '请输入名称' } ] }
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="style_codes"
                        label="规格"
                        rules={ [ { required: true, message: '请选择规格' } ] }
                    >
                        <SearchSelect url='/api/photo/style/search' mode={ "tags" } param={ {
                            status: 'online'
                        } } />
                    </Form.Item>
                    <Form.Item
                        name="tags"
                        label="标签"
                        rules={ [ { required: true, message: '请选择标签' } ] }

                    >
                        <GroupTagSelect
                            param={
                                {
                                    foreground: 1,
                                    limit: 10000
                                }
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="状态"
                        rules={ [ { required: true, message: '请选择状态' } ] }
                    >
                        <Select style={ { width: 100 } } placeholder="状态">
                            <Select.Option value="online">使用中</Select.Option>
                            <Select.Option value="underpick">待筛选</Select.Option>
                            <Select.Option value="offline">已下线</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="src_url"
                        label="图片"
                        rules={ [ { required: true, message: '请选择状态' } ] }
                    >
                        <ImageOssUpload
                            label='上传本地图片'
                            folder='foregrounds/'
                            previewImg={ true }
                            maxCount={ 20 }
                            uploadData={ {} }
                            readOnly={ param.code != null }
                            imageStyle={ {
                                maxHeight: 60,
                                maxWidth: 60,
                                marginBottom: 10
                            } }
                            display={ 'block' }
                            imageHeight={ 200 }
                        />
                    </Form.Item>
                </Col>
            </Row>

        </Form>





    </Drawer > )
}

export default Page;